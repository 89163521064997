export const DedicatedIPAccountTypes = {
  DEDICATED_MARKETING: 'DEDICATED_MARKETING',
  DEDICATED_TRANSACTIONAL: 'DEDICATED_TRANSACTIONAL'
};
export const DedicatedIPMarketingSetupTypes = {
  DEDICATED_MARKETING: 'DEDICATED_MARKETING',
  BOTH: 'BOTH'
};
export const DedicatedIPTransactionalSetupTypes = {
  DEDICATED_TRANSACTIONAL: 'DEDICATED_TRANSACTIONAL',
  BOTH: 'BOTH'
};
export const DedicatedIPWizardAccountTypes = {
  DEDICATED_MARKETING: 'DEDICATED_MARKETING',
  DEDICATED_TRANSACTIONAL: 'DEDICATED_TRANSACTIONAL',
  BOTH: 'BOTH'
};
export const AccountSetupStages = {
  SUBMIT_REQUEST: 'SUBMIT_REQUEST',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_HUBSPOT_DNS_RECORDS: 'CREATE_HUBSPOT_DNS_RECORDS',
  VALIDATE_LIVE_DNS: 'VALIDATE_LIVE_DNS',
  IP_WARMUP: 'IP_WARMUP',
  COMPLETE: 'COMPLETE'
};
/**
 * Enum for Dedicated IP setup stages.
 * Customers cannot Disconnect IPs past the "NOT_CONNECTED" stage!
 * @readonly
 * @enum {string}
 */
export const PublicAccountStages = {
  /** FE only value - corresponds to an empty placeholder row */
  UNINITIALIZED: 'UNINITIALIZED',
  /** IP with invalid DNS records; customer can still Disconnect this IP. */
  NOT_CONNECTED: 'NOT_CONNECTED',
  /** HubSpot won't begin IP warm-up until the customer 1) links BU(s) or 2) makes this the default */
  WAITING_FOR_DYNAMIC_PORTAL_ROUTE_TO_BE_LINKED: 'WAITING_FOR_DYNAMIC_PORTAL_ROUTE_TO_BE_LINKED',
  /** Customer is done setting up; waiting on HubSpot to begin IP warm up */
  WAITING_INTERNALLY_ON_HUBSPOT: 'WAITING_INTERNALLY_ON_HUBSPOT',
  /** HubSpot is shifting email sending traffic from HubSpot shared IPs to this one */
  WARMING_UP: 'WARMING_UP',
  /** Fully switched over to sending emails with this dedicated IP */
  CONNECTED: 'CONNECTED',
  /** DNS records were previously correct but became incorrect. Customer needs to fix them.  */
  DNS_MISCONFIGURATION: 'DNS_MISCONFIGURATION'
};
export const SET_UP_COMPLETED_STAGES = [PublicAccountStages.WAITING_INTERNALLY_ON_HUBSPOT, PublicAccountStages.WARMING_UP, PublicAccountStages.CONNECTED, PublicAccountStages.DNS_MISCONFIGURATION];
export const DedicatedIPDNSErrors = {
  FAILED_TO_RESOLVE_RECORDS: 'FAILED_TO_RESOLVE_RECORDS',
  RECORD_CONTENT_INCORRECT: 'RECORD_CONTENT_INCORRECT',
  NO_RECORDS_FOUND: 'NO_RECORDS_FOUND',
  FOUND_RECORD_AT_DOUBLE_DOMAIN: 'FOUND_RECORD_AT_DOUBLE_DOMAIN'
};
export const DedicatedIPBusinessUnitPrefix = 'BU:';