const SUCCESS = 'SUCCESS';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
const ERROR_NO_SPF = 'ERROR_NO_SPF';
const ERROR_SPF_EXISTS_MISSING_INCLUDE = 'ERROR_SPF_EXISTS_MISSING_INCLUDE';
const ERROR_INVALID_SPF_RECORD = 'ERROR_INVALID_SPF_RECORD';
const ERROR_BAD_DOMAIN = 'ERROR_BAD_DOMAIN';
const EXISTING_CNAME_ON_DOMAIN = 'EXISTING_CNAME_ON_DOMAIN';
const SpfCheckResultStatuses = {
  ERROR_INVALID_SPF_RECORD,
  ERROR_SPF_EXISTS_MISSING_INCLUDE,
  ERROR_NO_SPF,
  UNKNOWN_ERROR,
  SUCCESS,
  ERROR_BAD_DOMAIN,
  EXISTING_CNAME_ON_DOMAIN
};
export default SpfCheckResultStatuses;