import http from 'hub-http/clients/apiClient';
import NavItem from '../utils/NavItem';
import { getSessionSetting, setSessionSetting, SettingsKey } from '../utils/storageSettings';
const urlMap = {
  [SettingsKey.NAV_CONFIG_SETTINGS_V4]: 'navconfig/v4/navconfig/settings',
  [SettingsKey.NAV_CONFIG_SETTINGS_PREFERENCES]: 'navconfig/v2/navconfig/settings-preferences'
};
export function parse(config) {
  let parsedConfig = [];
  try {
    if (config && config.children && config.children.length > 0) {
      const navItems = config.children;
      parsedConfig = navItems.map(navItem => new NavItem(null, navItem));
    }
  } catch (e) {
    console.error(e);
  }
  return parsedConfig;
}
const cache = {};
const Config = {
  getLocal(navType) {
    const storedConfig = getSessionSetting(navType);
    return parse(storedConfig);
  },
  get(navType = SettingsKey.NAV_CONFIG_SETTINGS_V4) {
    const resource = urlMap[navType];
    if (!resource) {
      throw new Error('Unknown nav type');
    }
    if (typeof cache[navType] === 'undefined') {
      cache[navType] = http.get(resource).then(config => {
        const parsedConfig = parse(config);
        setTimeout(() => {
          setSessionSetting(navType, config);
        }, 0);
        return parsedConfig;
      }).catch(onrejected => {
        if (onrejected.status === 404) {
          // We should be able to ignore
          return;
        } else {
          console.error(onrejected);
        }
      });
    }
    // It's' explicitly assigned value above when - hence the non-null assertion
    return cache[navType];
  }
};
export default Config;