import * as SecurityHeaders from './SecurityHeaders';
const secondsPerDay = 86400;
function getI18nKey(suffix) {
  return `settings-ui-domains.securitySettings.securityHeaders.${SecurityHeaders.STRICT_TRANSPORT_SECURITY}.durations.${suffix}`;
}
export const DEFAULT_DURATION = {
  key: getI18nKey('sixWeeks'),
  value: secondsPerDay * 7 * 6
};
const HstsPredefinedDurations = [DEFAULT_DURATION, {
  key: getI18nKey('sixMonths'),
  value: secondsPerDay * 30 * 6
}, {
  key: getI18nKey('oneYear'),
  value: secondsPerDay * 365
}, {
  key: getI18nKey('twoYears'),
  value: secondsPerDay * 365 * 2
}];
export default HstsPredefinedDurations;