'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
export const defaultEditRedirectState = Immutable.fromJS({
  fetchEditRedirectRequestStatus: RequestStatus.UNINITIALIZED,
  editRedirectRequestStatus: RequestStatus.UNINITIALIZED,
  redirect: null
});
export default function EditRedirect(state = defaultEditRedirectState, action) {
  const {
    type,
    fetchEditRedirectRequestStatus,
    editRedirectRequestStatus,
    editProps,
    prop,
    selectedRedirect,
    redirect
  } = action;
  const previousRedirect = state.get('redirect') || Immutable.Map();
  switch (type) {
    case ActionTypes.FETCH_EDIT_URL_REDIRECT_ATTEMPTED:
    case ActionTypes.FETCH_EDIT_URL_REDIRECT_FAILED:
      return state.merge({
        fetchEditRedirectRequestStatus
      });
    case ActionTypes.FETCH_EDIT_URL_REDIRECT_SUCCEEDED:
      return state.merge({
        fetchEditRedirectRequestStatus,
        redirect
      });
    case ActionTypes.SELECT_EDIT_URL_REDIRECT:
      return state.merge({
        redirect: selectedRedirect
      });
    case ActionTypes.CLEAR_URL_REDIRECT_PROPS:
      return state.merge({
        redirect: previousRedirect.delete(prop)
      });
    case ActionTypes.UPDATE_EDIT_URL_REDIRECT:
      return state.merge({
        redirect: previousRedirect.merge(editProps)
      });
    case ActionTypes.EDIT_URL_REDIRECT_ATTEMPTED:
    case ActionTypes.EDIT_URL_REDIRECT_FAILED:
      return state.merge({
        editRedirectRequestStatus
      });
    case ActionTypes.EDIT_URL_REDIRECT_SUCCEEDED:
      return defaultEditRedirectState.merge({
        editRedirectRequestStatus
      });
    case ActionTypes.RESET_EDIT_URL_REDIRECT:
      return defaultEditRedirectState;
    default:
      return state;
  }
}