'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../Constants';
import * as ActionTypes from '../actions/ActionTypes';
const DEFAULT_AUTH_STATE = Immutable.Map({
  gates: [],
  scopes: [],
  user: {},
  portal: {},
  portalScopes: Immutable.List(),
  portalScopesRequestStatus: RequestStatus.UNINITIALIZED
});
export default function Auth(state = DEFAULT_AUTH_STATE, action) {
  switch (action.type) {
    case ActionTypes.FETCH_PORTAL_SCOPE_SUCCEEDED:
      return state.merge({
        portalScopes: action.scopes,
        portalScopesRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.FETCH_PORTAL_SCOPE_FAILED:
      return state.set('portalScopesRequestStatus', RequestStatus.FAILED);
    case ActionTypes.FETCH_PORTAL_SCOPE_ATTEMPTED:
      return state.set('portalScopesRequestStatus', RequestStatus.PENDING);
    default:
      return state;
  }
}