export const SUCCESS = 'SUCCESS';
export const PROCESSING = 'PROCESSING';
export const EXPIRING = 'EXPIRING';
export const EXPIRED = 'EXPIRED';
export const CAA_ERROR = 'CAA_ERROR';
export const MISSING_WHOIS_EMAIL_ERROR = 'MISSING_WHOIS_EMAIL_ERROR';
export const HTTP_TOKEN_VALIDATION_ERROR = 'HTTP_TOKEN_VALIDATION_ERROR';
export const RESOLVING_TO_WRONG_ZONE_ERROR = 'RESOLVING_TO_WRONG_ZONE_ERROR';
export const GOOGLE_SAFE_BROWSING_ERROR = 'GOOGLE_SAFE_BROWSING_ERROR';
export const PHISHTANK_ERROR = 'PHISHTANK_ERROR';
export const DIGICERT_BLACKLIST_ERROR = 'DIGICERT_BLACKLIST_ERROR';
export const COMMON_NAME_TOO_LONG = 'COMMON_NAME_TOO_LONG';
export const DOMAIN_VALIDATION_ERROR = 'DOMAIN_VALIDATION_ERROR';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const CLOUDFLARE_BLOCKED = 'CLOUDFLARE_BLOCKED';

/**
 * Intended to be a catch-all for error cases
 * The associated alert will direct users to the SSL certificate verification tool
 * Eventually, all other alerts other than SUCCESS and PROCESSING will likely go away in favor of this tool
 */
export const SSL_RENEWAL_FAILING = 'SSL_RENEWAL_FAILING';