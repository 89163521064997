'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../../Constants';
export default Immutable.Map({
  domainIds: Immutable.List(),
  domains: Immutable.Map(),
  setupTasks: Immutable.Map(),
  setupTasksRequestStatus: RequestStatus.UNINITIALIZED,
  domainRequestStatus: RequestStatus.UNINITIALIZED,
  createSetupTaskRequestStatus: RequestStatus.UNINITIALIZED,
  showingInternalDomains: false,
  primaryDomainUsageTypeAvailability: Immutable.List(),
  supportedDomainUsageTypesForCustomDomain: Immutable.List(),
  supportedDomainUsageTypeForHsSites: Immutable.List(),
  supportedSecondaryDomainUsageTypes: Immutable.List(),
  domainsRequiringMigrationToDcv: Immutable.List()
});