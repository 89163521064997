import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import Immutable from 'immutable';
import { DomainManagerScopes, HubSpotSupportScopes } from '../Constants';
export function fetchPortalScopes() {
  return http.get(`hubs2/v1/scopes/hub/${PortalIdParser.get()}/filter`, {
    query: {
      scopes: Object.values(DomainManagerScopes).concat(Object.values(HubSpotSupportScopes))
    }
  }).then(Immutable.fromJS);
}