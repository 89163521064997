export const routePrefix = 'routePrefix';
export const destination = 'destination';
export const redirectStyle = 'redirectStyle';
export const precedence = 'precedence';
export const isTrailingSlashOptional = 'isTrailingSlashOptional';
export const isMatchQueryString = 'isMatchQueryString';
export const isProtocolAgnostic = 'isProtocolAgnostic';
export const isOnlyAfterNotFound = 'isOnlyAfterNotFound';
export const created = 'created';
export const updated = 'updated';
export const isPattern = 'isPattern';
export const note = 'note';