import { parse } from 'hub-http/helpers/params';
import PortalIdParser from 'PortalIdParser';
const ESHREF_KEY = 'SETTINGS_NAV::cached-eschref';
const getPortalId = pathname => PortalIdParser.parsePortalIdFromString(pathname, PortalIdParser.pathRegex);
const isDefined = e => typeof e === 'string';
export const isValideEschref = (eschref, pathname, portalId) => {
  return isDefined(eschref) && eschref.indexOf('/') === 0 && eschref.indexOf('//') !== 0 && getPortalId(pathname) === portalId;
};
export const extractEschrefFromLocation = () => {
  const portalId = PortalIdParser.get();
  const {
    search,
    pathname
  } = window.location;
  const {
    eschref
  } = parse(String(search).substr(1));
  return !Array.isArray(eschref) && isValideEschref(eschref, pathname, portalId) ? eschref : null;
};
const ReturnLinkHelper = {
  hydrate() {
    const eschref = extractEschrefFromLocation();
    if (eschref) {
      window.sessionStorage.setItem(ESHREF_KEY, String(extractEschrefFromLocation()));
    }
  },
  get() {
    const eschref = extractEschrefFromLocation();
    const cachedEschref = window.sessionStorage.getItem(ESHREF_KEY);
    if (eschref) {
      return eschref;
    } else if (cachedEschref) {
      return cachedEschref;
    }
    return null;
  },
  clear() {
    window.sessionStorage.removeItem(ESHREF_KEY);
  }
};
export default ReturnLinkHelper;