import Immutable from 'immutable';
import * as UrlRedirectsFilters from './UrlRedirectsFilters';
const DefaultFetchParams = Immutable.Map({
  filter: UrlRedirectsFilters.ALL,
  offset: 0,
  sortOrder: 1,
  sortColumn: '',
  searchQuery: '',
  createdById: -1
});
export default DefaultFetchParams;