'use es6';

import { FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED, FETCH_DOWNGRADE_NOTIFICATIONS_FAILED, DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED } from '../../actions/ActionTypes';
import DEFAULT_STATE from '../../data/domains/DowngradeNotifications';
import { RequestStatus } from '../../Constants';
export default function DowngradeNotifications(state = DEFAULT_STATE, action) {
  const {
    type,
    downgrades,
    downgradeId
  } = action;
  switch (type) {
    case FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED:
      return state.merge({
        downgrades,
        requestStatus: RequestStatus.SUCCEEDED
      });
    case FETCH_DOWNGRADE_NOTIFICATIONS_FAILED:
      {
        return state.set('requestStatus', RequestStatus.FAILED);
      }
    case DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED:
      {
        return state.update('downgrades', currentDowngrades => currentDowngrades.filterNot(downgrade => downgrade.get('id') === downgradeId));
      }
    default:
      return state;
  }
}