export const FETCH_PORTAL_SCOPE_SUCCEEDED = 'FETCH_PORTAL_SCOPE_SUCCEEDED';
export const FETCH_PORTAL_SCOPE_FAILED = 'FETCH_PORTAL_SCOPE_FAILED';
export const FETCH_PORTAL_SCOPE_ATTEMPTED = 'FETCH_PORTAL_SCOPE_ATTEMPTED';
export const FETCH_BRANDS_ATTEMPTED = 'FETCH_BRANDS_ATTEMPTED';
export const FETCH_BRANDS_SUCCEEDED = 'FETCH_BRANDS_SUCCEEDED';
export const FETCH_BRANDS_FAILED = 'FETCH_BRANDS_FAILED';
export const ADD_BRAND_ATTEMPTED = 'ADD_BRAND_ATTEMPTED';
export const ADD_BRAND_SUCCEEDED = 'ADD_BRAND_SUCCEEDED';
export const ADD_BRAND_FAILED = 'ADD_BRAND_FAILED';
export const REPLACE_BRAND_ATTEMPTED = 'REPLACE_BRAND_ATTEMPTED';
export const REPLACE_BRAND_SUCCEEDED = 'REPLACE_BRAND_SUCCEEDED';
export const REPLACE_BRAND_FAILED = 'REPLACE_BRAND_FAILED';
export const DELETE_BRAND_ATTEMPTED = 'DELETE_BRAND_ATTEMPTED';
export const DELETE_BRAND_FAILED = 'DELETE_BRAND_FAILED';
export const DELETE_BRAND_SUCCEEDED = 'DELETE_BRAND_SUCCEEDED';
export const BRAND_TO_REPLACE_SELECTED = 'BRAND_TO_REPLACE_SELECTED';
export const NEW_BRAND_SELECTED = 'NEW_BRAND_SELECTED';
export const FETCH_DASHBOARD_ATTEMPTED = 'FETCH_DASHBOARD_ATTEMPTED';
export const FETCH_DASHBOARD_SUCCEEDED = 'FETCH_DASHBOARD_SUCCEEDED';
export const FETCH_DASHBOARD_FAILED = 'FETCH_DASHBOARD_FAILED';
export const FETCH_TASK_DOMAINS_ATTEMPTED = 'FETCH_TASK_DOMAINS_ATTEMPTED';
export const FETCH_TASK_DOMAINS_SUCCEEDED = 'FETCH_TASK_DOMAINS_SUCCEEDED';
export const FETCH_DOMAIN_TEAMS_ATTEMPTED = 'FETCH_DOMAIN_TEAMS_ATTEMPTED';
export const FETCH_DOMAIN_TEAMS_SUCCEEDED = 'FETCH_DOMAIN_TEAMS_SUCCEEDED';
export const FETCH_DOMAIN_TEAMS_FAILED = 'FETCH_DOMAIN_TEAMS_FAILED';
export const UPDATE_DOMAIN_TEAMS_ATTEMPTED = 'UPDATE_DOMAIN_TEAMS_ATTEMPTED';
export const UPDATE_DOMAIN_TEAMS_SUCCEEDED = 'UPDATE_DOMAIN_TEAMS_SUCCEEDED';
export const UPDATE_DOMAIN_TEAMS_FAILED = 'UPDATE_DOMAIN_TEAMS_FAILED';
export const REMOVE_CONTENT_TYPE_ATTEMPTED = 'REMOVE_CONTENT_TYPE_ATTEMPTED';
export const REMOVE_CONTENT_TYPE_SUCCEEDED = 'REMOVE_CONTENT_TYPE_SUCCEEDED';
export const REMOVE_CONTENT_TYPE_FAILED = 'REMOVE_CONTENT_TYPE_FAILED';
export const FETCH_PRIMARY_SETUP_TASKS_ATTEMPTED = 'FETCH_PRIMARY_SETUP_TASKS_ATTEMPTED';
export const FETCH_PRIMARY_SETUP_TASKS_SUCCEEDED = 'FETCH_PRIMARY_SETUP_TASKS_SUCCEEDED';
export const FETCH_PRIMARY_SETUP_TASKS_FAILED = 'FETCH_PRIMARY_SETUP_TASKS_FAILED';
export const FETCH_EMAIL_DOMAINS_ATTEMPTED = 'FETCH_EMAIL_DOMAINS_ATTEMPTED';
export const FETCH_EMAIL_DOMAINS_SUCCEEDED = 'FETCH_EMAIL_DOMAINS_SUCCEEDED';
export const FETCH_EMAIL_DOMAINS_FAILED = 'FETCH_EMAIL_DOMAINS_FAILED';
export const FETCH_SPF_RECORDS_ATTEMPTED = 'FETCH_SPF_RECORDS_ATTEMPTED';
export const FETCH_SPF_RECORDS_SUCCEEDED = 'FETCH_SPF_RECORDS_SUCCEEDED';
export const FETCH_SPF_RECORDS_FAILED = 'FETCH_SPF_RECORDS_FAILED';
export const FETCH_TASK_EMAIL_DOMAIN_ATTEMPTED = 'FETCH_TASK_EMAIL_DOMAIN_ATTEMPTED';
export const FETCH_TASK_EMAIL_DOMAIN_SUCCEEDED = 'FETCH_TASK_EMAIL_DOMAIN_SUCCEEDED';
export const FETCH_TRACKED_DOMAINS_ATTEMPTED = 'FETCH_TRACKED_DOMAINS_ATTEMPTED';
export const FETCH_TRACKED_DOMAINS_SUCCEEDED = 'FETCH_TRACKED_DOMAINS_SUCCEEDED';
export const FETCH_TRACKED_DOMAINS_FAILED = 'FETCH_TRACKED_DOMAINS_FAILED';
export const FETCH_VERIFY_TASK_DOMAINS_ATTEMPTED = 'FETCH_VERIFY_TASK_DOMAINS_ATTEMPTED';
export const FETCH_VERIFY_TASK_DOMAINS_SUCCEEDED = 'FETCH_VERIFY_TASK_DOMAINS_SUCCEEDED';
export const RESET_VERIFY_TASK_DOMAINS_REQUEST_STATUS = 'RESET_VERIFY_TASK_DOMAINS_REQUEST_STATUS';
export const FETCH_VERIFY_TASK_DOMAINS_FAILED = 'FETCH_VERIFY_TASK_DOMAINS_FAILED';
export const FETCH_VERIFY_TASK_EMAIL_DOMAIN_ATTEMPTED = 'FETCH_VERIFY_TASK_EMAIL_DOMAIN_ATTEMPTED';
export const FETCH_VERIFY_TASK_EMAIL_DOMAIN_FAILED = 'FETCH_VERIFY_TASK_EMAIL_DOMAIN_FAILED';
export const FETCH_VERIFY_TASK_EMAIL_DOMAIN_SUCCEEDED = 'FETCH_VERIFY_TASK_EMAIL_DOMAIN_SUCCEEDED';
export const REMOVE_DOMAIN_ATTEMPTED = 'REMOVE_DOMAIN_ATTEMPTED';
export const REMOVE_DOMAIN_SUCCEEDED = 'REMOVE_DOMAIN_SUCCEEDED';
export const REMOVE_DOMAIN_FAILED = 'REMOVE_DOMAIN_FAILED';
export const REMOVE_EMAIL_DOMAIN_ATTEMPTED = 'REMOVE_EMAIL_DOMAIN_ATTEMPTED';
export const REMOVE_EMAIL_DOMAIN_SUCCEEDED = 'REMOVE_EMAIL_DOMAIN_SUCCEEDED';
export const REMOVE_EMAIL_DOMAIN_FAILED = 'REMOVE_EMAIL_DOMAIN_FAILED';
export const VALIDATE_DOMAIN_ATTEMPTED = 'VALIDATE_DOMAIN_ATTEMPTED';
export const VALIDATE_DOMAIN_SUCCEEDED = 'VALIDATE_DOMAIN_SUCCEEDED';
export const VALIDATE_DOMAIN_FAILED = 'VALIDATE_DOMAIN_FAILED';
export const VALIDATE_SUBDOMAIN_PICKER_ROW_ATTEMPTED = 'VALIDATE_SUBDOMAIN_PICKER_ROW_ATTEMPTED';
export const VALIDATE_SUBDOMAIN_PICKER_ROW_SUCCEEDED = 'VALIDATE_SUBDOMAIN_PICKER_ROW_SUCCEEDED';
export const VALIDATE_SUBDOMAIN_PICKER_ROW_FAILED = 'VALIDATE_SUBDOMAIN_PICKER_ROW_FAILED';
export const VALIDATE_REPLACEMENT_DOMAIN_ATTEMPTED = 'VALIDATE_REPLACEMENT_DOMAIN_ATTEMPTED';
export const VALIDATE_REPLACEMENT_DOMAIN_SUCCEEDED = 'VALIDATE_REPLACEMENT_DOMAIN_SUCCEEDED';
export const VALIDATE_REPLACEMENT_DOMAIN_FAILED = 'VALIDATE_REPLACEMENT_DOMAIN_FAILED';
export const VALIDATE_REPLACEMENT_SUBDOMAIN_SUCCEEDED = 'VALIDATE_REPLACEMENT_SUBDOMAIN_SUCCEEDED';
export const VALIDATE_REPLACEMENT_SUBDOMAIN_FAILED = 'VALIDATE_REPLACEMENT_SUBDOMAIN_FAILED';
export const RESET_DOMAIN_WIZARD = 'RESET_DOMAIN_WIZARD';
export const ADD_DOMAINS_ATTEMPTED = 'ADD_DOMAINS_ATTEMPTED';
export const ADD_DOMAINS_SUCCEEDED = 'ADD_DOMAINS_SUCCEEDED';
export const ADD_DOMAINS_FAILED = 'ADD_DOMAINS_FAILED';
export const ADD_DOMAIN_AFTER_PURCHASE_FAILED = 'ADD_DOMAIN_AFTER_PURCHASE_FAILED';
export const ADD_EMAIL_DOMAIN_ATTEMPTED = 'ADD_EMAIL_DOMAIN_ATTEMPTED';
export const ADD_EMAIL_DOMAIN_SUCCEEDED = 'ADD_EMAIL_DOMAIN_SUCCEEDED';
export const SELECT_DOMAIN = 'SELECT_DOMAIN';
export const DESELECT_DOMAIN = 'DESELECT_DOMAIN';
export const REQUIRE_HTTPS = 'REQUIRE_HTTPS';
export const MANUALLY_MARK_AS_RESOLVING = 'MANUALLY_MARK_AS_RESOLVING';
export const ADD_SETUP_DOMAIN_USAGE_TYPE = 'ADD_SETUP_DOMAIN_USAGE_TYPE';
export const REMOVE_SETUP_DOMAIN_USAGE_TYPE = 'REMOVE_SETUP_DOMAIN_USAGE_TYPE';
export const UPDATE_CONTENT_TYPE = 'UPDATE_CONTENT_TYPE';
export const UPDATE_REDIRECT_TARGET = 'UPDATE_REDIRECT_TARGET';
export const UPDATE_SUBDOMAIN_PRIMARY_LANGUAGE = 'UPDATE_SUBDOMAIN_PRIMARY_LANGUAGE';
export const UPDATE_SUBDOMAIN_STRING = 'UPDATE_SUBDOMAIN_STRING';
export const UPDATE_SUFFIX_STRING = 'UPDATE_SUFFIX_STRING';
export const UPDATE_ALL_SUBDOMAIN_STRINGS = 'UPDATE_ALL_SUBDOMAIN_STRINGS';
export const UPDATE_ALL_SUFFIX_STRINGS = 'UPDATE_ALL_SUFFIX_STRINGS';
export const UPDATE_REPLACEMENT_SUBDOMAIN_STRING = 'UPDATE_REPLACEMENT_SUBDOMAIN_STRING';
export const UPDATE_ALL_REPLACEMENT_SUBDOMAIN_STRINGS = 'UPDATE_ALL_REPLACEMENT_SUBDOMAIN_STRINGS';
export const UPDATE_REPLACEMENT_SUFFIX_STRING = 'UPDATE_REPLACEMENT_SUFFIX_STRING';
export const UPDATE_ALL_REPLACEMENT_SUFFIX_STRINGS = 'UPDATE_ALL_REPLACEMENT_SUFFIX_STRINGS';
export const UPDATE_DOMAIN_NAME = 'UPDATE_DOMAIN_NAME';
export const ADD_STAGED_DOMAIN_USAGE_TYPE = 'ADD_STAGED_DOMAIN_USAGE_TYPE';
export const REMOVE_STAGED_DOMAIN_USAGE_TYPE = 'REMOVE_STAGED_DOMAIN_USAGE_TYPE';
export const UPDATE_STAGED_DOMAIN_USAGE_TYPES = 'UPDATE_STAGED_DOMAIN_USAGE_TYPES';
export const UPDATE_DOMAIN_ATTEMPTED = 'UPDATE_DOMAIN_ATTEMPTED';
export const UPDATE_DOMAIN_SUCCEEDED = 'UPDATE_DOMAIN_SUCCEEDED';
export const UPDATE_DOMAIN_FAILED = 'UPDATE_DOMAIN_FAILED';
export const CHECK_DNS_PROVIDER_ATTEMPTED = 'CHECK_DNS_PROVIDER_ATTEMPTED';
export const CHECK_DNS_PROVIDER_SUCCEEDED = 'CHECK_DNS_PROVIDER_SUCCEEDED';
export const CHECK_DNS_PROVIDER_FAILED = 'CHECK_DNS_PROVIDER_FAILED';
export const APPLY_DOMAIN_TEMPLATE_ATTEMPTED = 'APPLY_DOMAIN_TEMPLATE_ATTEMPTED';
export const APPLY_DOMAIN_TEMPLATE_SUCCEEDED = 'APPLY_DOMAIN_TEMPLATE_SUCCEEDED';
export const APPLY_DOMAIN_TEMPLATE_FAILED = 'APPLY_DOMAIN_TEMPLATE_FAILED';
export const CREATE_SETUP_TASK_ATTEMPTED = 'CREATE_SETUP_TASK_ATTEMPTED';
export const CREATE_SETUP_TASK_SUCCEEDED = 'CREATE_SETUP_TASK_SUCCEEDED';
export const CREATE_SETUP_TASK_FAILED = 'CREATE_SETUP_TASK_FAILED';
export const FETCH_EDIT_URL_REDIRECT_ATTEMPTED = 'FETCH_EDIT_URL_REDIRECT_ATTEMPTED';
export const FETCH_EDIT_URL_REDIRECT_FAILED = 'FETCH_EDIT_URL_REDIRECT_FAILED';
export const FETCH_EDIT_URL_REDIRECT_SUCCEEDED = 'FETCH_EDIT_URL_REDIRECT_SUCCEEDED';
export const FETCH_URL_REDIRECTS_ATTEMPTED = 'FETCH_URL_REDIRECTS_ATTEMPTED';
export const FETCH_URL_REDIRECTS_FAILED = 'FETCH_URL_REDIRECTS_FAILED';
export const FETCH_URL_REDIRECTS_SUCCEEDED = 'FETCH_URL_REDIRECTS_SUCCEEDED';
export const UPDATE_SEARCH_QUERY_PARAM = 'UPDATE_SEARCH_QUERY_PARAM';
export const UPDATE_NEW_URL_REDIRECT = 'UPDATE_NEW_URL_REDIRECT';
export const CREATE_URL_REDIRECT_ATTEMPTED = 'CREATE_URL_REDIRECT_ATTEMPTED';
export const CREATE_URL_REDIRECT_SUCCEEDED = 'CREATE_URL_REDIRECT_SUCCEEDED';
export const CREATE_URL_REDIRECT_FAILED = 'CREATE_URL_REDIRECT_FAILED';
export const DELETE_URL_REDIRECT_REQUEST_ATTEMPTED = 'DELETE_URL_REDIRECT_REQUEST_ATTEMPTED';
export const DELETE_URL_REDIRECT_REQUEST_FAILED = 'DELETE_URL_REDIRECT_REQUEST_FAILED';
export const DELETE_URL_REDIRECT_REQUEST_SUCCEEDED = 'DELETE_URL_REDIRECT_REQUEST_SUCCEEDED';
export const RESET_EDIT_URL_REDIRECT = 'RESET_EDIT_URL_REDIRECT';
export const CLEAR_URL_REDIRECT_PROPS = 'CLEAR_URL_REDIRECT_PROPS';
export const SELECT_EDIT_URL_REDIRECT = 'SELECT_EDIT_URL_REDIRECT';
export const UPDATE_EDIT_URL_REDIRECT = 'UPDATE_EDIT_URL_REDIRECT';
export const EDIT_URL_REDIRECT_ATTEMPTED = 'EDIT_URL_REDIRECT_ATTEMPTED';
export const EDIT_URL_REDIRECT_FAILED = 'EDIT_URL_REDIRECT_FAILED';
export const EDIT_URL_REDIRECT_SUCCEEDED = 'EDIT_URL_REDIRECT_SUCCEEDED';
export const SWITCH_TABLE_COLUMNS = 'SWITCH_TABLE_COLUMNS';
export const BULK_ACTIONS_SELECT_URL_REDIRECTS = 'BULK_ACTIONS_SELECT_URL_REDIRECTS';
export const BULK_ACTIONS_DESELECT_URL_REDIRECTS = 'BULK_ACTIONS_DESELECT_URL_REDIRECTS';
export const BULK_ACTION_SUCCEEDED = 'BULK_ACTION_SUCCEEDED';
export const BULK_ACTION_ATTEMPTED = 'BULK_ACTION_ATTEMPTED';
export const BULK_ACTION_FAILED = 'BULK_ACTION_FAILED';
export const FETCH_HUB_USERS_SUCCEEDED = 'FETCH_HUB_USERS_SUCCEEDED';
export const FETCH_REDIRECT_CREATORS_SUCCEEDED = 'FETCH_REDIRECT_CREATORS_SUCCEEDED';
export const SET_REDIRECTS_IMPORTED_FILE_ID = 'SET_REDIRECTS_IMPORTED_FILE_ID';
export const FETCH_DOMAIN_LANGUAGE_ATTEMPTED = 'FETCH_DOMAIN_LANGUAGE_ATTEMPTED';
export const FETCH_DOMAIN_LANGUAGE_FAILED = 'FETCH_DOMAIN_LANGUAGE_FAILED';
export const FETCH_DOMAIN_LANGUAGE_SUCCEEDED = 'FETCH_DOMAIN_LANGUAGE_SUCCEEDED';
export const PREPROCESS_REDIRECTS_ATTEMPTED = 'PREPROCESS_REDIRECTS_ATTEMPTED';
export const PREPROCESS_REDIRECTS_SUCCEEDED = 'PREPROCESS_REDIRECTS_SUCCEEDED';
export const PREPROCESS_REDIRECTS_FAILED = 'PREPROCESS_REDIRECTS_FAILED';
export const TOGGLE_OVERWRITE_EXISTING_REDIRECTS = 'TOGGLE_OVERWRITE_EXISTING_REDIRECTS';
export const TOGGLE_OVERWRITE_EXISTING_NOTE = 'TOGGLE_OVERWRITE_EXISTING_NOTE';
export const MAP_REDIRECT_FIELD = 'MAP_REDIRECT_FIELD';
export const CLEAR_MAPPED_REDIRECT_FIELD = 'CLEAR_MAPPED_REDIRECT_FIELD';
export const UPDATE_IMPORT_REDIRECTS_NOTE = 'UPDATE_IMPORT_REDIRECTS_NOTE';
export const INITIALIZE_IMPORTS_STATE = 'INITIALIZE_IMPORTS_STATE';
export const IMPORT_REDIRECTS_ATTEMPTED = 'IMPORT_REDIRECTS_ATTEMPTED';
export const DELETE_DOMAIN_ALERT = 'DELETE_DOMAIN_ALERT';
export const FETCH_BUSINESS_UNITS_SUCCEEDED = 'FETCH_BUSINESS_UNITS_SUCCEEDED';
export const FETCH_BUSINESS_UNITS_ATTEMPTED = 'FETCH_BUSINESS_UNITS_ATTEMPTED';
export const FETCH_BUSINESS_UNITS_FAILED = 'FETCH_BUSINESS_UNITS_FAILED';
export const FETCH_SECURITY_SETTINGS_SUCCEEDED = 'FETCH_SECURITY_SETTINGS_SUCCEEDED';
export const FETCH_SECURITY_SETTINGS_ATTEMPTED = 'FETCH_SECURITY_SETTINGS_ATTEMPTED';
export const FETCH_SECURITY_SETTINGS_FAILED = 'FETCH_SECURITY_SETTINGS_FAILED';
export const UPDATE_SECURITY_SETTINGS_SUCCEEDED = 'UPDATE_SECURITY_SETTINGS_SUCCEEDED';
export const UPDATE_SECURITY_SETTINGS_ATTEMPTED = 'UPDATE_SECURITY_SETTINGS_ATTEMPTED';
export const UPDATE_SECURITY_SETTINGS_FAILED = 'UPDATE_SECURITY_SETTINGS_FAILED';
export const REMOVE_STAGED_SECURITY_SETTING = 'REMOVE_STAGED_SECURITY_SETTING';
export const INIT_SECURITY_SETTING = 'INIT_SECURITY_SETTING';
export const UPDATE_STAGED_SECURITY_SETTING = 'UPDATE_STAGED_SECURITY_SETTING';
export const FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED = 'FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED';
export const FETCH_DOWNGRADE_NOTIFICATIONS_FAILED = 'FETCH_DOWNGRADE_NOTIFICATIONS_FAILED';
export const DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED = 'DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED';

/**
 * @deprecated - moving Dedicated IP actions to v2 thunk
 */
export const ADD_DEDICATED_IP_ATTEMPTED = 'ADD_DEDICATED_IP_ATTEMPTED';
export const ADD_DEDICATED_IP_SUCCEEDED = 'ADD_DEDICATED_IP_SUCCEEDED';
export const ADD_DEDICATED_IP_FAILED = 'ADD_DEDICATED_IP_FAILED';
export const FETCH_DEDICATED_IP_DNS_RECORDS_ATTEMPTED = 'FETCH_DEDICATED_IP_DNS_RECORDS_ATTEMPTED';
export const FETCH_DEDICATED_IP_DNS_RECORDS_SUCCEEDED = 'FETCH_DEDICATED_IP_DNS_RECORDS_SUCCEEDED';
export const FETCH_DEDICATED_IP_DNS_RECORDS_FAILED = 'FETCH_DEDICATED_IP_DNS_RECORDS_FAILED';
export const VERIFY_DEDICATED_IP_DNS_RECORDS_ATTEMPTED = 'VERIFY_DEDICATED_IP_DNS_RECORDS_ATTEMPTED';
export const VERIFY_DEDICATED_IP_DNS_RECORDS_SUCCEEDED = 'VERIFY_DEDICATED_IP_DNS_RECORDS_SUCCEEDED';
export const VERIFY_DEDICATED_IP_DNS_RECORDS_FAILED = 'VERIFY_DEDICATED_IP_DNS_RECORDS_FAILED';
export const UPDATE_BRAND_BUSINESS_UNIT_ATTEMPTED = 'UPDATE_BRAND_BUSINESS_UNIT_ATTEMPTED';
export const UPDATE_BRAND_BUSINESS_UNIT_SUCCEEDED = 'UPDATE_BRAND_BUSINESS_UNIT_SUCCEEDED';
export const UPDATE_BRAND_BUSINESS_UNIT_FAILED = 'UPDATE_BRAND_BUSINESS_UNIT_FAILED';
export const UPDATE_HAS_REACHED_DNS_VERIFY_STEP = 'UPDATE_HAS_REACHED_DNS_VERIFY_STEP';
export const UPDATE_FINAL_STEP_SCREEN = 'UPDATE_FINAL_STEP_SCREEN';
export const UPDATE_VERIFICATION_ENDING_TIME = 'UPDATE_VERIFICATION_ENDING_TIME';
export const POPULATE_REPLACE_BRANDS_FIELDS_FROM_SETUP_TASK = 'POPULATE_REPLACE_BRANDS_FIELDS_FROM_SETUP_TASK';
export const ENABLE_DID_UTILIZE_DOMAIN_CONNECT = 'ENABLE_DID_UTILIZE_DOMAIN_CONNECT';

// Show DNS records
export const FETCH_DNS_RECORDS_BY_DOMAIN_ATTEMPTED = 'FETCH_DNS_RECORDS_BY_DOMAIN_ATTEMPTED';
export const FETCH_DNS_RECORDS_BY_DOMAIN_SUCCEEDED = 'FETCH_DNS_RECORDS_BY_DOMAIN_SUCCEEDED';
export const FETCH_DNS_RECORDS_BY_DOMAIN_FAILED = 'FETCH_DNS_RECORDS_BY_DOMAIN_FAILED';

//ReverseProxy
export const ReverseProxyActions = {
  testsStarted: 'reverseProxy/testsStarted',
  testsSucceeded: 'reverseProxy/testsSucceeded',
  testsFailed: 'reverseProxy/testsFailed'
};
export const SynchronousDomainConnectActions = {
  getDnsRecordUrlsAttempted: 'synchronousDomainConnect/getDnsRecordUrlsAttempted',
  getDnsRecordUrlsSucceeded: 'synchronousDomainConnect/getDnsRecordUrlsSucceeded',
  getDnsRecordUrlsFailed: 'synchronousDomainConnect/getDnsRecordUrlsFailed',
  getDnsRecordUrlsForSslPreProvisioningFailed: 'getDnsRecordUrlsForSslPreProvisioningFailed',
  updateRecordStatus: 'synchronousDomainConnect/updateRecordStatus'
};
export const CreateReverseProxyActions = {
  builtInCdnToggled: 'createReverseProxy/builtInCdnToggled',
  creationStarted: 'createReverseProxy/creationStarted',
  creationSucceeded: 'createReverseProxy/creationSucceeded',
  creationFailed: 'createReverseProxy/creationFailed'
};
export const AutomaticRedirectActions = {
  initialize: 'automaticRedirectActions/initialize',
  update: 'automaticRedirectActions/update',
  reset: 'automaticRedirectActions/reset'
};
export const SslVerificationActions = {
  initialize: 'sslVerificationActions/initialize',
  verificationSucceeded: 'sslVerificationActions/succeeded',
  verificationFailed: 'sslVerificationActions/failed',
  clearState: 'sslVerificationActions/clearState'
};