'use es6';

import FileUploadStatus from '../../enums/redirects/FileUploadStatus';
import { RequestStatus } from '../../Constants';
export function getPreprocessedPropertyMatchedColumn(preprocessedProperty) {
  return preprocessedProperty.get('matchedColumnName');
}
export function getPreprocessedPropertyFileHeaderName(preprocessedProperty) {
  return preprocessedProperty.get('headerColumnName');
}
export function getPreprocessedPropertySampleValue(preprocessedProperty) {
  return preprocessedProperty.get('sampleValue');
}
export function getSystemColumnNameAssociated(redirectPreprocessedColumn, currentMappedColumnNames) {
  const preprocessedMatechedColumnName = getPreprocessedPropertyMatchedColumn(redirectPreprocessedColumn);
  if (preprocessedMatechedColumnName && redirectPreprocessedColumn.get('cleared') !== true) {
    return preprocessedMatechedColumnName;
  }
  return currentMappedColumnNames.findKey(fileHeaderColumnName => fileHeaderColumnName === getPreprocessedPropertyFileHeaderName(redirectPreprocessedColumn));
}
export const initializeMappedColumns = preprocessedData => {
  return preprocessedData.filter(preprocessedColumn => getPreprocessedPropertyMatchedColumn(preprocessedColumn) !== null).toMap().mapKeys((key, matchedColumn) => getPreprocessedPropertyMatchedColumn(matchedColumn)).map(matchedColumn => getPreprocessedPropertyFileHeaderName(matchedColumn));
};
export const clearMappedField = (fileHeaderColumnName, mappedColumns) => {
  return mappedColumns.filter(mappedFileHeader => mappedFileHeader !== fileHeaderColumnName);
};
export const clearPreprocessedDataField = (fileHeaderColumnName, preprocessedData) => {
  return preprocessedData.map(preprocessedColumn => {
    if (getPreprocessedPropertyFileHeaderName(preprocessedColumn) === fileHeaderColumnName) {
      return preprocessedColumn.set('cleared', true);
    }
    return preprocessedColumn;
  });
};
export const getIsCsvFile = file => file.get('extension').toLowerCase() === 'csv';
export function getFileUploadComponentStatus(file, preprocessFileRequestStatus) {
  if (preprocessFileRequestStatus === RequestStatus.FAILED) {
    return FileUploadStatus.FAILED;
  }
  if (preprocessFileRequestStatus === RequestStatus.SUCCEEDED && file) {
    if (getIsCsvFile(file)) {
      return FileUploadStatus.SUCCEEDED;
    }
    return FileUploadStatus.FAILED;
  }
  return FileUploadStatus.DEFAULT;
}