'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import DEFAULT_STATE, { reverseProxyChecksForDomainDefaultState } from '../../data/domains/EditDomain';
import formatReverseProxyChecks from '../../utils/domains/formatReverseProxyChecks';
function removeStagedDomainUsageTypeWithValidation(state, removedStagedDomainUsageType) {
  const stagedDomainUsageTypes = state.get('stagedDomainUsageTypes');
  const removalIndex = stagedDomainUsageTypes.indexOf(removedStagedDomainUsageType);
  if (removalIndex !== -1) {
    return stagedDomainUsageTypes.delete(removalIndex);
  }
  return stagedDomainUsageTypes;
}

// Avoid duplicates - don't add if already staged
// Note: UI *should* never allow this, but this is to double check
function addStagedDomainUsageTypeWithValidation(state, newStagedDomainUsageType) {
  const stagedDomainUsageTypes = state.get('stagedDomainUsageTypes');
  if (!stagedDomainUsageTypes.includes(newStagedDomainUsageType)) {
    return stagedDomainUsageTypes.push(newStagedDomainUsageType);
  }
  return stagedDomainUsageTypes;
}
export default function EditDomain(state = DEFAULT_STATE, action) {
  const {
    type,
    checked,
    domainId,
    editDropdownDomainUsageType,
    stagedDomainUsageTypes,
    newStagedDomainUsageType,
    removedStagedDomainUsageType,
    dnsRecords,
    failedChecks,
    passingChecks
  } = action;
  switch (type) {
    case ActionTypes.REMOVE_DOMAIN_ATTEMPTED:
    case ActionTypes.REMOVE_EMAIL_DOMAIN_ATTEMPTED:
    case ActionTypes.UPDATE_DOMAIN_ATTEMPTED:
    case ActionTypes.REMOVE_CONTENT_TYPE_ATTEMPTED:
    case ActionTypes.UPDATE_DOMAIN_TEAMS_ATTEMPTED:
      return state.merge({
        updateRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.REMOVE_DOMAIN_SUCCEEDED:
    case ActionTypes.REMOVE_EMAIL_DOMAIN_SUCCEEDED:
    case ActionTypes.UPDATE_DOMAIN_SUCCEEDED:
    case ActionTypes.REMOVE_CONTENT_TYPE_SUCCEEDED:
    case ActionTypes.UPDATE_DOMAIN_TEAMS_SUCCEEDED:
      return state.merge({
        updateRequestStatus: RequestStatus.SUCCEEDED,
        domainId: -1,
        editDropdownDomainUsageType: null
      });
    case ActionTypes.REMOVE_DOMAIN_FAILED:
    case ActionTypes.REMOVE_EMAIL_DOMAIN_FAILED:
    case ActionTypes.UPDATE_DOMAIN_FAILED:
    case ActionTypes.REMOVE_CONTENT_TYPE_FAILED:
    case ActionTypes.UPDATE_DOMAIN_TEAMS_FAILED:
      return state.merge({
        updateRequestStatus: RequestStatus.FAILED,
        domainId: -1,
        editDropdownDomainUsageType: null
      });
    case ActionTypes.REQUIRE_HTTPS:
      return state.merge({
        requireHttps: checked
      });
    case ActionTypes.MANUALLY_MARK_AS_RESOLVING:
      return state.merge({
        manuallyMarkedAsResolving: checked
      });
    case ActionTypes.SELECT_DOMAIN:
      return DEFAULT_STATE.merge({
        domainId,
        editDropdownDomainUsageType,
        stagedDomainUsageTypes
      });
    case ActionTypes.DESELECT_DOMAIN:
      return DEFAULT_STATE;
    case ActionTypes.ADD_STAGED_DOMAIN_USAGE_TYPE:
      return state.merge({
        stagedDomainUsageTypes: addStagedDomainUsageTypeWithValidation(state, newStagedDomainUsageType)
      });
    case ActionTypes.REMOVE_STAGED_DOMAIN_USAGE_TYPE:
      return state.merge({
        stagedDomainUsageTypes: removeStagedDomainUsageTypeWithValidation(state, removedStagedDomainUsageType)
      });
    case ActionTypes.UPDATE_STAGED_DOMAIN_USAGE_TYPES:
      return state.merge({
        stagedDomainUsageTypes
      });
    case ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_ATTEMPTED:
      return state.merge({
        dsnRecordsForDomain: Immutable.Map({
          fetchStatus: RequestStatus.PENDING
        })
      });
    case ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_SUCCEEDED:
      return state.merge({
        dsnRecordsForDomain: Immutable.Map({
          fetchStatus: RequestStatus.SUCCEEDED,
          records: dnsRecords
        })
      });
    case ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_FAILED:
      return state.merge({
        dsnRecordsForDomain: Immutable.Map({
          fetchStatus: RequestStatus.FAILED
        })
      });
    case ActionTypes.ReverseProxyActions.testsStarted:
      return state.set('reverseProxyCheckForDomain', Object.assign({}, reverseProxyChecksForDomainDefaultState, {
        fetchStatus: RequestStatus.PENDING
      }));
    case ActionTypes.ReverseProxyActions.testsSucceeded:
      return state.set('reverseProxyCheckForDomain', Object.assign({}, reverseProxyChecksForDomainDefaultState, {
        fetchStatus: RequestStatus.SUCCEEDED,
        checks: formatReverseProxyChecks({
          failedChecks,
          passingChecks
        }),
        failedChecks
      }));
    case ActionTypes.ReverseProxyActions.testsFailed:
      return state.set('reverseProxyCheckForDomain', Object.assign({}, reverseProxyChecksForDomainDefaultState, {
        fetchStatus: RequestStatus.FAILED
      }));
    default:
      return state;
  }
}