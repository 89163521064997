'use es6';

import Immutable from 'immutable';
import * as DomainSSLAlertTypes from '../../enums/domains/DomainSSLAlertTypes';
import * as DomainSslHelpLinks from '../../enums/domains/DomainSslHelpLinks';
import { getDomainIdAsString } from './Domains';
import compareNumbersDescending from '../compareNumbersDescending';
import getCloudflareSupportEmailHref from './getCloudflareSupportEmailHref';
import { CAA_ERROR, CLOUDFLARE_BLOCKED } from '../../enums/domains/DomainSSLAlertTypes';
import { getIsDomainSetupComplete } from './getIsDomainSetupComplete';
import { getRelativeUrlToSslModalWithCurrentPath } from './getRelativeUrlToSslModalWithCurrentPath';
function getI18nKey(suffix) {
  return `settings-ui-domains.dashboard.notification.sslStatus.${suffix}`;
}
function sortDomainAlertsByMostRecent(domainAlerts) {
  return domainAlerts.sortBy(domainAlert => domainAlert.get('created'), compareNumbersDescending);
}
export const ALERT_TYPE_AND_CTA_PER_SSL = {
  [DomainSSLAlertTypes.SUCCESS]: {
    alertType: 'success'
  },
  [DomainSSLAlertTypes.PROCESSING]: {
    alertType: 'info',
    ctaUrl: DomainSslHelpLinks.SSL_PROCESSING_LINK,
    ctaIsLink: true,
    i18nCtaTextKeyPath: getI18nKey('learnMore')
  },
  [DomainSSLAlertTypes.EXPIRING]: {
    alertType: 'warning',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.CAA_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.ERROR_ISSUING_SSL_CERTIFICATE,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('learnMore')
  },
  [DomainSSLAlertTypes.RESOLVING_TO_WRONG_ZONE_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.UPDATE_CNAME_RECORD_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('learnMore')
  },
  [DomainSSLAlertTypes.GOOGLE_SAFE_BROWSING_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.DOMAIN_UNSAFE_BY_GOOGLE_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('learnMore')
  },
  [DomainSSLAlertTypes.PHISHTANK_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.DOMAIN_UNSAFE_BY_PHISHTANK_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('learnMore')
  },
  [DomainSSLAlertTypes.DIGICERT_BLACKLIST_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.COMMON_NAME_TOO_LONG]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.EXPIRED]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.MISSING_WHOIS_EMAIL_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.HTTP_TOKEN_VALIDATION_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.DOMAIN_VALIDATION_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  },
  [DomainSSLAlertTypes.CLOUDFLARE_BLOCKED]: {
    alertType: 'danger',
    getCtaUrl({
      domainName
    }) {
      return getCloudflareSupportEmailHref(domainName);
    },
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('emailSupport')
  },
  [DomainSSLAlertTypes.SSL_RENEWAL_FAILING]: {
    alertType: 'danger',
    getCtaUrl({
      domainId
    }) {
      return getRelativeUrlToSslModalWithCurrentPath(window.location.pathname, domainId);
    },
    isExternalLink: false,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('fixSslRenewal')
  },
  [DomainSSLAlertTypes.UNKNOWN_ERROR]: {
    alertType: 'danger',
    ctaUrl: DomainSslHelpLinks.HELP_LINK,
    ctaIsButton: true,
    i18nCtaTextKeyPath: getI18nKey('contactUs')
  }
};

// These alert types are considered "duplicate" in the sense that they should not be shown for portals ungated to domains:SslProvisioning
const DUPLICATE_SSL_ALERT_TYPES = [DomainSSLAlertTypes.EXPIRING, DomainSSLAlertTypes.EXPIRED, DomainSSLAlertTypes.CAA_ERROR, DomainSSLAlertTypes.HTTP_TOKEN_VALIDATION_ERROR, DomainSSLAlertTypes.DOMAIN_VALIDATION_ERROR, DomainSSLAlertTypes.UNKNOWN_ERROR, DomainSSLAlertTypes.CLOUDFLARE_BLOCKED];
export const DEFAULT_ALERT_TYPE_AND_CTA = ALERT_TYPE_AND_CTA_PER_SSL[DomainSSLAlertTypes.DOMAIN_VALIDATION_ERROR];
export const GOOGLE_TRUST_CAA_TEXT = `0 issue 'pki.goog'`;
function isBlockingError(domainAlert) {
  return [CAA_ERROR, CLOUDFLARE_BLOCKED].includes(domainAlert.get('type'));
}

/**
 * Insert a new domain alert. As a side effect, delete older alerts of the same type and ID
 */
function processDomainAlert(domainAlert, allDomainAlerts, domainObj) {
  const duplicateAlertKey = allDomainAlerts.findKey(existingAlert => existingAlert.get('type') === domainAlert.get('type') && existingAlert.get('domainId') === domainObj.get('id').toString() && existingAlert.get('created') <= domainAlert.get('created'));

  // Ensures that we prioritize the most recent alerts of the same type and ID
  if (duplicateAlertKey) {
    allDomainAlerts = allDomainAlerts.delete(duplicateAlertKey);
  }
  allDomainAlerts = allDomainAlerts.set(domainAlert.get('id'), domainAlert.merge({
    domainName: domainObj.get('domain'),
    domainId: getDomainIdAsString(domainObj)
  }));
  return allDomainAlerts;
}
function processSslRenewalFailingAlert(domainObj, allDomainAlerts) {
  // Nothing to do if we can't find data on "isSslCertRenewalFailing"
  if (!domainObj.getIn(['domainCdnConfig', 'isSslCertRenewalFailing'], false /* fallback via noSetValue */)) {
    return allDomainAlerts;
  }
  const newAlert = Immutable.fromJS({
    type: DomainSSLAlertTypes.SSL_RENEWAL_FAILING,
    id: domainObj.get('id'),
    created: new Date().getTime(),
    domainName: domainObj.get('domain'),
    domainId: getDomainIdAsString(domainObj),
    tokens: {}
  });
  return processDomainAlert(newAlert, allDomainAlerts, domainObj);
}
export function getDomainAlertsFromDomains(domains, isUngatedForSslProvisioningTool) {
  return sortDomainAlertsByMostRecent(domains.reduce((domainAlerts, domainObj) => {
    domainObj.get('domainAlerts').forEach(domainAlert => {
      if (!getIsDomainSetupComplete(domainObj) && !isBlockingError(domainAlert)) {
        return;
      }

      /**
       * If ungated for the SSL Provisioning Tool, there are certain alerts that we do not want to show
       * This is because we instead direct users to the new tool, which will give them more information about these errors
       * Note that the longer term solution is to have the Backend only return the proper domain alerts according to the gate status
       * More information at: https://git.hubteam.com/HubSpot/cos-domains/issues/3219
       */
      if (!isUngatedForSslProvisioningTool || !DUPLICATE_SSL_ALERT_TYPES.includes(domainAlert.get('type'))) {
        domainAlerts = processDomainAlert(domainAlert, domainAlerts, domainObj);
      }
    });
    if (isUngatedForSslProvisioningTool) {
      return processSslRenewalFailingAlert(domainObj, domainAlerts);
    }
    return domainAlerts;
  }, Immutable.OrderedMap({})));
}
export function deleteDomainAlertByAlertId(domainAlerts, alertId) {
  return domainAlerts.delete(alertId);
}
export function deleteDomainAlertByDomainId(domainAlerts, domainId) {
  return domainAlerts.filter(domainAlert => domainAlert.get('domainId') !== domainId);
}