import events from '../../events.yaml';
import { createTracker } from 'usage-tracker';
const tracker = createTracker({
  events,
  properties: {
    namespace: 'Settings'
  },
  lastKnownEventProperties: ['screen']
});
const trackedActionsByEvent = {};
export function trackInteractionOnce(eventName, eventMetadata) {
  const {
    action
  } = eventMetadata;
  if (
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  trackedActionsByEvent[eventName] &&
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  trackedActionsByEvent[eventName].includes(action)) {
    return;
  }
  tracker.track(eventName, eventMetadata);
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (!trackedActionsByEvent[eventName]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    trackedActionsByEvent[eventName] = [];
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  trackedActionsByEvent[eventName].push(action);
}
export default tracker;