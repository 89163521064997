'use es6';

const FIVE_SECONDS = 5000;
const HALF_SECOND = 500;
export const withRateLimiting = (apiCall, intervalTime = FIVE_SECONDS, waitingTime = HALF_SECOND) => {
  let previousTime = 0;
  let previousResponse = Promise.resolve();
  return (...params) => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - previousTime;
    previousTime = currentTime;

    // Call the API and return the result if enough time has passed
    if (timeElapsed > intervalTime) {
      previousTime = currentTime;
      previousResponse = apiCall(...params);
      return previousResponse;
    }

    // Otherwise, return the previous response
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        previousResponse = previousResponse.then(() => apiCall(...params)).then(resolve).catch(reject);
      }, waitingTime);
    });
  };
};