export const CONTENT_SECURITY_POLICY = 'CONTENT_SECURITY_POLICY';
export const CONTENT_SECURITY_POLICY_REPORT_ONLY = 'CONTENT_SECURITY_POLICY_REPORT_ONLY';
export const X_FRAME_OPTIONS = 'X_FRAME_OPTIONS';
export const REFERRER_POLICY = 'REFERRER_POLICY';
export const FEATURE_POLICY = 'FEATURE_POLICY';
export const PERMISSIONS_POLICY = 'PERMISSIONS_POLICY';
export const X_CONTENT_TYPE_OPTIONS = 'X_CONTENT_TYPE_OPTIONS';
export const X_XSS_PROTECTION = 'X_XSS_PROTECTION';
export const HTTPS = 'HTTPS';
export const TLS = 'TLS';
export const STRICT_TRANSPORT_SECURITY = 'STRICT_TRANSPORT_SECURITY';
export const SecurityHeaders = {
  CONTENT_SECURITY_POLICY,
  CONTENT_SECURITY_POLICY_REPORT_ONLY,
  X_FRAME_OPTIONS,
  REFERRER_POLICY,
  FEATURE_POLICY,
  PERMISSIONS_POLICY,
  X_CONTENT_TYPE_OPTIONS,
  X_XSS_PROTECTION,
  HTTPS,
  TLS,
  STRICT_TRANSPORT_SECURITY
};