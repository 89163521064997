'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import * as RedirectTypes from '../../enums/redirects/RedirectTypes';
import * as RedirectProperties from '../../enums/redirects/RedirectProperties';
import RedirectStyles from '../../enums/redirects/RedirectStyles';
export const defaultAddRedirectState = Immutable.fromJS({
  addRedirectRequestStatus: RequestStatus.UNINITIALIZED,
  [RedirectProperties.routePrefix]: '',
  [RedirectProperties.destination]: '',
  redirectType: RedirectTypes.STANDARD,
  redirectStyle: RedirectStyles.PERMANENT,
  precedence: 0,
  note: '',
  isMatchQueryString: false,
  isTrailingSlashOptional: true,
  isProtocolAgnostic: true,
  isOnlyAfterNotFound: false
});
export default function AddRedirect(state = defaultAddRedirectState, action) {
  const {
    type,
    addRedirectRequestStatus,
    redirectProps
  } = action;
  switch (type) {
    case ActionTypes.UPDATE_NEW_URL_REDIRECT:
      return state.merge(Object.assign({}, redirectProps));
    case ActionTypes.CREATE_URL_REDIRECT_ATTEMPTED:
    case ActionTypes.CREATE_URL_REDIRECT_FAILED:
      return state.merge({
        addRedirectRequestStatus
      });
    case ActionTypes.CREATE_URL_REDIRECT_SUCCEEDED:
      return defaultAddRedirectState.merge({
        addRedirectRequestStatus
      });
    default:
      return state;
  }
}