'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import DefaultFetchParams from '../../enums/redirects/DefaultFetchParams';
import RequiredColumnNames from '../../enums/redirects/RequiredColumnNames';
import DefaultCustomColumns from '../../enums/redirects/DefaultCustomColumns';
export const defaultRedirectsState = Immutable.fromJS({
  urlRedirectsRequestStatus: RequestStatus.UNINITIALIZED,
  deleteRedirectRequestStatus: RequestStatus.UNINITIALIZED,
  bulkActionRequestStatus: RequestStatus.UNINITIALIZED,
  redirects: Immutable.List(),
  tableColumns: RequiredColumnNames.concat(DefaultCustomColumns),
  urlRedirectsCount: 0,
  fetchParams: DefaultFetchParams,
  selectedRedirectsIds: Immutable.Set(),
  redirectCreatorIds: Immutable.List()
});
function findAndReplaceEditedRedirect(redirects, updatedRedirect) {
  return redirects.map(redirect => {
    if (redirect.get('id') === updatedRedirect.get('id')) {
      return updatedRedirect;
    }
    return redirect;
  });
}
function getFilteredOutRedirects(redirectsIdsToBeDeselected, redirectsCurrentlySelected) {
  return redirectsCurrentlySelected.filter(redirectId => !redirectsIdsToBeDeselected.contains(redirectId));
}
function getNewSelectedRedirects(redirectsIdsToBeSelected, redirectsCurrentlySelected) {
  return redirectsCurrentlySelected.concat(redirectsIdsToBeSelected);
}
function getRefreshedRedirectsAfterDeletion(state, deletedRedirectId) {
  return getFilteredOutRedirects(Immutable.List([deletedRedirectId]), state.get('selectedRedirectsIds'));
}
export default function Redirects(state = defaultRedirectsState, action) {
  const {
    type,
    redirects,
    urlRedirectsCount,
    fetchParams,
    searchQuery,
    updatedRedirect,
    tableColumns,
    redirectsIds,
    deletedRedirectId,
    redirectCreatorIds
  } = action;
  switch (type) {
    case ActionTypes.UPDATE_SEARCH_QUERY_PARAM:
      return state.merge({
        fetchParams: state.get('fetchParams').merge({
          searchQuery
        })
      });
    case ActionTypes.FETCH_URL_REDIRECTS_ATTEMPTED:
      return state.merge({
        fetchParams,
        urlRedirectsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_URL_REDIRECTS_FAILED:
      return state.merge({
        urlRedirectsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_URL_REDIRECTS_SUCCEEDED:
      return state.merge({
        urlRedirectsRequestStatus: RequestStatus.SUCCEEDED,
        redirects,
        urlRedirectsCount
      });
    case ActionTypes.DELETE_URL_REDIRECT_REQUEST_ATTEMPTED:
      return state.merge({
        deleteRedirectRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.DELETE_URL_REDIRECT_REQUEST_SUCCEEDED:
      return state.merge({
        deleteRedirectRequestStatus: RequestStatus.SUCCEEDED,
        selectedRedirectsIds: getRefreshedRedirectsAfterDeletion(state, deletedRedirectId)
      });
    case ActionTypes.DELETE_URL_REDIRECT_REQUEST_FAILED:
      return state.merge({
        deleteRedirectRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.EDIT_URL_REDIRECT_SUCCEEDED:
      return state.merge({
        redirects: findAndReplaceEditedRedirect(state.get('redirects'), updatedRedirect)
      });
    case ActionTypes.SWITCH_TABLE_COLUMNS:
      return state.merge({
        tableColumns: RequiredColumnNames.concat(tableColumns)
      });
    case ActionTypes.BULK_ACTIONS_SELECT_URL_REDIRECTS:
      return state.merge({
        selectedRedirectsIds: getNewSelectedRedirects(redirectsIds, state.get('selectedRedirectsIds'))
      });
    case ActionTypes.BULK_ACTIONS_DESELECT_URL_REDIRECTS:
      return state.merge({
        selectedRedirectsIds: getFilteredOutRedirects(redirectsIds, state.get('selectedRedirectsIds'))
      });
    case ActionTypes.BULK_ACTION_FAILED:
      return state.merge({
        bulkActionRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.BULK_ACTION_ATTEMPTED:
      return state.merge({
        bulkActionRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.BULK_ACTION_SUCCEEDED:
      return state.merge({
        bulkActionRequestStatus: RequestStatus.SUCCEEDED,
        selectedRedirectsIds: Immutable.Set()
      });
    case ActionTypes.FETCH_REDIRECT_CREATORS_SUCCEEDED:
      return state.merge({
        redirectCreatorIds
      });
    default:
      return state;
  }
}