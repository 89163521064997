'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { arePortalDomainsAlreadyFetched, convertDomainsListToMap, getDomainIdAsString, removeDomainById, getDomainsWithUpdatedTaskDomains, convertSetupTasksListToMap, updatePrimaryDomainUsageTypeAvailabilityAfterRemoval } from '../../utils/domains/Domains';
import { removeDomainByIdWithRedirects } from '../../utils/domains/removeDomainByIdWithRedirects';
import formatDashboardResponse from '../../utils/domains/formatDashboardResponse';
import { RequestStatus } from '../../Constants';
import DEFAULT_STATE from '../../data/domains/Domains';
export default function Domains(state = DEFAULT_STATE, action) {
  const {
    type,
    domain,
    domains,
    domainId,
    newDomains,
    domainType,
    domainUsageType,
    newDomainIds,
    setupTasks,
    includeInternal,
    dashboardResponse,
    isUngatedForAutomaticRedirect
  } = action;
  switch (type) {
    case ActionTypes.FETCH_DASHBOARD_ATTEMPTED:
      return state.merge({
        domainRequestStatus: RequestStatus.PENDING,
        showingInternalDomains: includeInternal
      });
    case ActionTypes.FETCH_DASHBOARD_FAILED:
      return state.merge({
        domainRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_DASHBOARD_SUCCEEDED:
      return state.merge(Object.assign({
        domainRequestStatus: RequestStatus.SUCCEEDED
      }, formatDashboardResponse(dashboardResponse)));
    case ActionTypes.FETCH_PRIMARY_SETUP_TASKS_ATTEMPTED:
      return state.merge({
        setupTasksRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_PRIMARY_SETUP_TASKS_SUCCEEDED:
      return state.merge({
        setupTasks: convertSetupTasksListToMap(setupTasks),
        setupTasksRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.FETCH_PRIMARY_SETUP_TASKS_FAILED:
      return state.merge({
        setupTasksRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_VERIFY_TASK_DOMAINS_SUCCEEDED:
      return state.merge({
        domains: getDomainsWithUpdatedTaskDomains(domains, state.get('domains'))
      });
    case ActionTypes.FETCH_TASK_DOMAINS_SUCCEEDED:
      {
        if (arePortalDomainsAlreadyFetched(state.get('domains'))) {
          return state;
        }
        return state.merge({
          domainIds: domains.map(domainObj => getDomainIdAsString(domainObj)),
          domains: convertDomainsListToMap(domains, getDomainIdAsString)
        });
      }
    case ActionTypes.ADD_DOMAINS_SUCCEEDED:
      return state.merge({
        domainIds: state.get('domainIds').concat(newDomainIds),
        domains: state.get('domains').merge(convertDomainsListToMap(newDomains, getDomainIdAsString))
      });
    case ActionTypes.REMOVE_DOMAIN_SUCCEEDED:
      // eslint-disable-next-line no-case-declarations
      if (isUngatedForAutomaticRedirect) {
        const domainsWithRemoval = removeDomainByIdWithRedirects(state.get('domains').toArray(), domainId.toString());
        return state.merge({
          domainIds: domainsWithRemoval.toArray().map(domainObj => getDomainIdAsString(domainObj)),
          domains: domainsWithRemoval,
          primaryDomainUsageTypeAvailability: updatePrimaryDomainUsageTypeAvailabilityAfterRemoval(state.get('primaryDomainUsageTypeAvailability'), domainType, domainUsageType)
        });
      }
      return state.merge({
        domainIds: state.get('domainIds').filter(id => id !== domainId.toString()),
        domains: removeDomainById(state.get('domains'), domainId.toString()),
        primaryDomainUsageTypeAvailability: updatePrimaryDomainUsageTypeAvailabilityAfterRemoval(state.get('primaryDomainUsageTypeAvailability'), domainType, domainUsageType)
      });
    case ActionTypes.REMOVE_CONTENT_TYPE_SUCCEEDED:
      return state.merge({
        domains: state.get('domains').set(getDomainIdAsString(domain), domain),
        primaryDomainUsageTypeAvailability: updatePrimaryDomainUsageTypeAvailabilityAfterRemoval(state.get('primaryDomainUsageTypeAvailability'), domainType, domainUsageType)
      });
    case ActionTypes.UPDATE_DOMAIN_SUCCEEDED:
      return state.setIn(['domains', getDomainIdAsString(domain)], domain);
    case ActionTypes.CREATE_SETUP_TASK_ATTEMPTED:
      return state.merge({
        createSetupTaskRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.CREATE_SETUP_TASK_SUCCEEDED:
      return state.merge({
        createSetupTaskRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.CREATE_SETUP_TASK_FAILED:
      return state.merge({
        createSetupTaskRequestStatus: RequestStatus.UNINITIALIZED
      });
    default:
      return state;
  }
}