export let SettingsKey;
(function (SettingsKey) {
  SettingsKey["EXPANDED_SETTINGS"] = "SETTINGS_NAV:EXPANDED_ITEMS";
  SettingsKey["NAV_CONFIG_SETTINGS"] = "SETTINGS_NAV:NAV_CONFIG";
  SettingsKey["NAV_CONFIG_SETTINGS_V4"] = "SETTINGS_NAV:NAV_CONFIG_V4";
  SettingsKey["NAV_CONFIG_SETTINGS_PREFERENCES"] = "SETTINGS_PREFERENCES_NAV:NAV_CONFIG";
  SettingsKey["HAS_SEEN_WELCOME_DIALOG"] = "SETTINGS_NAV:SEEN_WELCOME_DIALOG";
})(SettingsKey || (SettingsKey = {}));
export const expandedSettingsKey = SettingsKey.EXPANDED_SETTINGS;
export const navConfigSettingsKey = SettingsKey.NAV_CONFIG_SETTINGS;
export const navConfigSettingsV4Key = SettingsKey.NAV_CONFIG_SETTINGS_V4;
export const navConfigSettingsPreferencesKey = SettingsKey.NAV_CONFIG_SETTINGS_PREFERENCES;
export const hasSeenWelcomeDialogKey = SettingsKey.HAS_SEEN_WELCOME_DIALOG;
export const getSessionSetting = key => {
  try {
    const storedValue = window.sessionStorage.getItem(key);
    return storedValue && JSON.parse(storedValue);
  } catch (e) {
    console.error(e);
  }
};
export const setSessionSetting = (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};
export const getLocalSetting = key => {
  try {
    const storedValue = window.localStorage.getItem(key);
    return storedValue && JSON.parse(storedValue);
  } catch (e) {
    console.error(e);
  }
};
export const setLocalSetting = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};