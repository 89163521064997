'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../../Constants';
export default Immutable.Map({
  portalBrandCount: 0,
  allowedBrandCount: 0,
  brands: Immutable.List(),
  brandsRequestStatus: RequestStatus.UNINITIALIZED,
  addBrandRequestStatus: RequestStatus.UNINITIALIZED,
  deleteBrandRequestStatus: RequestStatus.UNINITIALIZED,
  updateBrandBusinessUnitRequestStatus: RequestStatus.UNINITIALIZED
});