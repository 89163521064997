import { UsageTypeIndices, DomainUsageTypeToUsageTypesMap, UsageTypeToDomainUsageTypesMap, DomainUsageTypeDisplayOrder, PrimaryUsageTypes, SecondaryUsageTypes } from '../../Constants';
export function getPrimaryUsageTypes(domainObj) {
  const usageTypes = domainObj.get('usageTypes');
  return usageTypes.filter(usageType => usageType in PrimaryUsageTypes);
}
export function getSecondaryUsageTypes(domainObj) {
  const usageTypes = domainObj.get('usageTypes');
  return usageTypes.filter(usageType => usageType in SecondaryUsageTypes);
}

// Returns true if a domain object has either the USED_FOR or PRIMARY_FOR usage types
export function getIsDomainObjectHostingDomainUsageType(domainObj, domainUsageType) {
  const desiredUsageTypes = DomainUsageTypeToUsageTypesMap[domainUsageType];
  return !!domainObj && !!domainObj.get('usageTypes') && domainObj.get('usageTypes').some(objUsageType => desiredUsageTypes.includes(objUsageType));
}

// Provided a Domain Usage Type, this func removes both PRIMARY_FOR and USED_FOR children
export function removeDomainUsageType(domainUsageType, existingUsageTypes) {
  const usageTypesToRemove = DomainUsageTypeToUsageTypesMap[domainUsageType];
  return existingUsageTypes.filter(usageType => !usageTypesToRemove.includes(usageType));
}

// Provided a child usage type, attempts to remove it AND any sibling usage types
// e.g. Provided USED_FOR_BLOG, tries to remove both USED_FOR_BLOG and PRIMARY_FOR_BLOG
export function removeUsageTypeAndSibling(usageType, existingUsageTypes) {
  const domainUsageType = UsageTypeToDomainUsageTypesMap[usageType];
  return removeDomainUsageType(domainUsageType, existingUsageTypes);
}

// Attempts to add a new usage type to existing list
// Checks for duplicates plus primary and secondary business logic
export function addUsageType(selectedUsageType, existingUsageTypes) {
  if (!existingUsageTypes) {
    return [selectedUsageType];
  }

  // Don't add duplicates
  if (existingUsageTypes.includes(selectedUsageType)) {
    return existingUsageTypes;
  }

  // If adding primary, first check for and remove equivalent USED_FOR sibling
  const isPrimary = (selectedUsageType in PrimaryUsageTypes);
  if (isPrimary) {
    const cleanedExistingUsageTypes = removeUsageTypeAndSibling(selectedUsageType, existingUsageTypes);
    return [...cleanedExistingUsageTypes, selectedUsageType];
  }

  // Do not add a secondary if we already have a sibling primary
  const domainUsageType = UsageTypeToDomainUsageTypesMap[selectedUsageType];
  const siblingPrimaryUsageType = DomainUsageTypeToUsageTypesMap[domainUsageType][UsageTypeIndices.PRIMARY_FOR];
  if (!existingUsageTypes.includes(siblingPrimaryUsageType)) {
    return [...existingUsageTypes, selectedUsageType];
  }
  return existingUsageTypes;
}

// Attempts to add an array of child usage types to existing list
export function addUsageTypes(selectedUsageTypes, existingUsageTypes) {
  let updatedUsageTypes = existingUsageTypes;
  selectedUsageTypes.forEach(selectedUsageType => {
    updatedUsageTypes = addUsageType(selectedUsageType, updatedUsageTypes);
  });
  return updatedUsageTypes;
}
export function getHostedDomainUsageTypes(domainObj) {
  return DomainUsageTypeDisplayOrder.filter(domainUsageType => getIsDomainObjectHostingDomainUsageType(domainObj, domainUsageType));
}
export function orderDomainUsageTypes(givenDomainUsageTypes) {
  return DomainUsageTypeDisplayOrder.filter(domainUsageType => givenDomainUsageTypes.includes(domainUsageType));
}
export function areUsageTypesOnlyForUnsupportedDomainUsageTypes(usageTypes, supportedDomainUsageTypes) {
  if (usageTypes.length === 0) {
    return false;
  }
  const isUsedForSomeSupportedUsageTypes = usageTypes.some(usageType => supportedDomainUsageTypes.includes(UsageTypeToDomainUsageTypesMap[usageType]));
  return !isUsedForSomeSupportedUsageTypes;
}
export function isDomainPrimaryOnlyForUnsupportedUsageTypes(domainObj, supportedDomainUsageTypes) {
  const usageTypes = getPrimaryUsageTypes(domainObj);
  return areUsageTypesOnlyForUnsupportedDomainUsageTypes(usageTypes, supportedDomainUsageTypes);
}