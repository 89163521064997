const findNavItem = (pathname, navGroups) => {
  if (!navGroups) {
    return undefined;
  }
  let match;
  navGroups.some(navGroup => {
    if (!navGroup) {
      return false;
    }
    navGroup.some(navItem => {
      const found = navItem.findMatchingItem(pathname);
      if (found) {
        match = found;
        return true;
      }
      return false;
    });
    if (match) {
      return true;
    }
    return false;
  });
  return match;
};
export default findNavItem;