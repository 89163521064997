import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { getHubUsersMapFromList as getHubUsersFromList } from '../../utils/redirects/hubUsers';
export const defaultHubUsersState = Immutable.Map({
  hubUsers: {}
});
export default function HubUsers(state = defaultHubUsersState, action) {
  const {
    type,
    hubUsers
  } = action;
  switch (type) {
    case ActionTypes.FETCH_HUB_USERS_SUCCEEDED:
      return state.set('hubUsers', getHubUsersFromList(hubUsers));
    default:
      return state;
  }
}