'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import getFormattedSecuritySettings from '../../utils/domains/getFormattedSecuritySettings';
import { DEFAULT as DEFAULT_TLS_VERSION } from '../../enums/domains/TlsOptions';
const defaultState = Immutable.fromJS({
  initialTlsVersion: DEFAULT_TLS_VERSION,
  securitySettings: {},
  stagedChanges: {},
  fetchSecuritySettingsRequestStatus: RequestStatus.UNINITIALIZED,
  updateSecuritySettingsRequestStatus: RequestStatus.UNINITIALIZED,
  domainId: null
});
export default function DomainSecuritySettings(state = defaultState, action) {
  const {
    type,
    securitySettings,
    domainId,
    securityHeader,
    updates,
    initialSecuritySettingValues
  } = action;
  const pathToStagedSetting = ['stagedChanges', securityHeader];
  switch (type) {
    case ActionTypes.FETCH_SECURITY_SETTINGS_SUCCEEDED:
      return state.merge({
        initialTlsVersion: securitySettings.get('minimumTlsVersion') || DEFAULT_TLS_VERSION,
        securitySettings: Immutable.fromJS(securitySettings.get('domainSettings')),
        stagedChanges: getFormattedSecuritySettings(securitySettings),
        fetchSecuritySettingsRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.FETCH_SECURITY_SETTINGS_ATTEMPTED:
      return state.merge({
        fetchSecuritySettingsRequestStatus: RequestStatus.PENDING,
        domainId
      });
    case ActionTypes.FETCH_SECURITY_SETTINGS_FAILED:
      return defaultState.merge({
        fetchSecuritySettingsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.REMOVE_STAGED_SECURITY_SETTING:
      return state.setIn(['stagedChanges', securityHeader], undefined);
    case ActionTypes.INIT_SECURITY_SETTING:
      return state.setIn(['stagedChanges', securityHeader], Immutable.fromJS(initialSecuritySettingValues) || true);
    case ActionTypes.UPDATE_STAGED_SECURITY_SETTING:
      return state.mergeDeepIn(pathToStagedSetting, Immutable.fromJS(updates));
    case ActionTypes.UPDATE_SECURITY_SETTINGS_SUCCEEDED:
      return state.set('updateSecuritySettingsRequestStatus', RequestStatus.SUCCEEDED);
    case ActionTypes.UPDATE_SECURITY_SETTINGS_ATTEMPTED:
      return state.set('updateSecuritySettingsRequestStatus', RequestStatus.PENDING);
    case ActionTypes.UPDATE_SECURITY_SETTINGS_FAILED:
      return state.set('updateSecuritySettingsRequestStatus', RequestStatus.FAILED);
    default:
      return state;
  }
}