'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { deleteDomainAlertByAlertId, deleteDomainAlertByDomainId, getDomainAlertsFromDomains } from '../../utils/domains/domainAlerts';
import getDomainSSLAlertHasValidAlertType from '../../utils/domains/getDomainSSLAlertHasValidAlertType';
import DEFAULT_STATE from '../../data/domains/DomainAlerts';
export default function DomainAlerts(state = DEFAULT_STATE, action) {
  const {
    type,
    dashboardResponse,
    alertId,
    domainId,
    isUngatedForSslProvisioningTool
  } = action;
  switch (type) {
    case ActionTypes.DELETE_DOMAIN_ALERT:
      return state.merge({
        domainAlerts: deleteDomainAlertByAlertId(state.get('domainAlerts'), alertId)
      });
    case ActionTypes.FETCH_DASHBOARD_SUCCEEDED:
      return state.merge({
        domainAlerts: getDomainAlertsFromDomains(dashboardResponse.get('domains'), isUngatedForSslProvisioningTool).filter(getDomainSSLAlertHasValidAlertType)
      });
    case ActionTypes.REMOVE_DOMAIN_SUCCEEDED:
      return state.merge({
        domainAlerts: deleteDomainAlertByDomainId(state.get('domainAlerts'), domainId.toString())
      });
    default:
      return state;
  }
}