import { userInfoSync } from 'hub-http/userInfo';
import formatName from 'I18n/utils/formatName';
const getFormattedCustomerName = () => {
  const {
    user: {
      first_name: firstName,
      last_name: lastName
    }
  } = userInfoSync();
  return formatName({
    firstName,
    lastName
  });
};
const address = 'abusereply@cloudflare.com';
const subject = 'Blocked custom hostname';
const body = domainName => `Hello,

I am attempting to onboard the active custom hostname ${domainName} to the Hubspot SSL for SaaS platform. It is currently blocked by Cloudflare. Could you please unblock this custom hostname for me?

Thanks,
${getFormattedCustomerName()}`;
export default function getCloudflareSupportEmailHref(domainName) {
  return encodeURI(`mailto:${address}?subject=${subject}&body=${body(domainName)}`);
}