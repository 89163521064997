import http from 'hub-http/clients/apiClient';
import { createStack } from 'hub-http';
import hubapiStack from 'hub-http/stacks/hubapi';
import promiseClient from 'hub-http/adapters/promiseClient';
import { retry } from 'hub-http/middlewares/core';

//@ts-expect-error not migrated yet
import { withRateLimiting } from './utils';
import { DedicatedIPBusinessUnitPrefix } from '../enums/DedicatedIP';
const DEDICATED_IP_URI = 'dedicated-ip-setup/v1';
const ACCOUNT_TYPE_PATH = {
  DEDICATED_MARKETING: 'dedicated-marketing',
  DEDICATED_TRANSACTIONAL: 'dedicated-transactional'
};
const DNS_CONFIG_ACCOUNT_TYPE_PATH = {
  DEDICATED_MARKETING: 'marketing',
  DEDICATED_TRANSACTIONAL: 'transactional'
};
const retryMiddleware = retry(({
  status
}) => {
  return status !== 200;
}, {
  delay: 3000,
  maxRetries: 5
});
const retryClient = promiseClient(createStack(retryMiddleware, hubapiStack));
export default class DedicatedIPAPIv2 {}
DedicatedIPAPIv2.fetchDedicatedIPNeedsAccountSetup = () => {
  return http.get(`${DEDICATED_IP_URI}/account-setup-needed`);
};
DedicatedIPAPIv2.fetchAllDedicatedIPStatuses = ({
  accountType
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.get(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}/all`);
};
DedicatedIPAPIv2.addDedicatedIP = ({
  accountType,
  domainName
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.post(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}`, {
    data: {
      domainName
    }
  });
};
DedicatedIPAPIv2.disconnectDedicatedIP = ({
  accountType,
  smtpDomain
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.delete(`${DEDICATED_IP_URI}/disconnect-account/${accountTypePath}/${smtpDomain}`);
};
DedicatedIPAPIv2.fetchDedicatedIPDNSRecords = ({
  accountType,
  smtpDomain
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return retryClient.get(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}/dns-records-to-setup/${smtpDomain}`);
};
DedicatedIPAPIv2.verifyDedicatedIPDNSRecords = withRateLimiting(({
  accountType,
  smtpDomain
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.put(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}/dns-records-to-setup/refresh/${smtpDomain}`);
});
DedicatedIPAPIv2.validateDedicatedIPDNSConfiguration = ({
  accountType,
  smtpDomain
}) => {
  const validateAccountTypePath = DNS_CONFIG_ACCOUNT_TYPE_PATH[accountType];
  return http.post(`${DEDICATED_IP_URI}/dns/configuration/${validateAccountTypePath}/validate/${smtpDomain}`);
};
DedicatedIPAPIv2.updateDedicatedIPBusinessUnits = ({
  accountType,
  smtpDomain,
  businessUnits
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  const businessUnitAdditionalRoutingKeys = businessUnits.map(businessUnit => `${DedicatedIPBusinessUnitPrefix}${businessUnit}`);
  return http.put(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}/dynamic-portal-route/${smtpDomain}`, {
    data: {
      additionalRoutingKeys: businessUnitAdditionalRoutingKeys
    }
  });
};
DedicatedIPAPIv2.makeDedicatedIPDefault = ({
  accountType,
  smtpDomain
}) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.post(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}/dynamic-portal-route/${smtpDomain}/default`);
};