import quickFetch from 'quick-fetch';
export default (name => {
  const earlyMetaRequest = quickFetch.getRequestStateByName(name);
  if (!earlyMetaRequest) return null;
  return new Promise((resolve, reject) => {
    earlyMetaRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(name);
    });
    earlyMetaRequest.onError((xhr, errorCode) => {
      reject(new Error(errorCode));
      quickFetch.removeEarlyRequest(name);
    });
  });
});