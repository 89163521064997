'use es6';

import Immutable from 'immutable';
import { getIsHostingDomain, extractSubdomainFromFullDomainName } from './Domains';
export function getDerivedBrandName(domain) {
  return domain.get('derivedBrandName');
}
export function getNonBrandRedirectDomainsDerivedBrandNames(redirectDomains, brandNamesList) {
  const nonBrandRedirectDomainsDerivedBrandNames = redirectDomains.filter(redirectDomain => !brandNamesList.includes(getDerivedBrandName(redirectDomain))).map(nonBrandDomain => getDerivedBrandName(nonBrandDomain));
  if (!nonBrandRedirectDomainsDerivedBrandNames) {
    return Immutable.List();
  }
  return nonBrandRedirectDomainsDerivedBrandNames.toSet().toList();
}
export const getIsBrandNameARedirect = (redirectDomains, brandNamesList, brandName) => {
  const redirectBrandNames = getNonBrandRedirectDomainsDerivedBrandNames(redirectDomains, brandNamesList);
  return !!brandName && redirectBrandNames.includes(brandName);
};
export function getSubdomainFromInput(domain, brand, topLevelDomain) {
  return domain.substring(0, domain.indexOf(`${brand}.${topLevelDomain}`) - 1);
}
export const getAllHostingDomainsAssociatedWithBrand = (brandNameToDomainsMap, brand) => {
  const domainsAssociatedWithBrand = brandNameToDomainsMap.get(brand);
  if (domainsAssociatedWithBrand) {
    return domainsAssociatedWithBrand.filter(getIsHostingDomain);
  }
  return Immutable.List();
};
export const getAllHostingDomainNamesAssociatedWithBrand = (brandNameToDomainsMap, brand) => {
  const allAssociatedDomainObjects = getAllHostingDomainsAssociatedWithBrand(brandNameToDomainsMap, brand);
  return allAssociatedDomainObjects ? allAssociatedDomainObjects.map(domainObj => domainObj.get('domain')) : Immutable.List();
};
export const getIsBrandHostingAnyDomains = (brandNameToDomainsMap, brand) => {
  const hostingDomains = getAllHostingDomainsAssociatedWithBrand(brandNameToDomainsMap, brand);
  return hostingDomains && !hostingDomains.isEmpty();
};
export function getSubdomainFromObj(domain) {
  return extractSubdomainFromFullDomainName(domain.get('domain'), domain.get('apexDomain'));
}
export const getTopLevelDomain = domain => domain.get('publicSuffix');
export const getConnectedReplacementDomain = (brandNameToDomainsMap, replacementBrand, domainToReplace) => {
  const domainsAssociatedWithReplacementBrand = brandNameToDomainsMap.get(replacementBrand);
  if (domainsAssociatedWithReplacementBrand) {
    return domainsAssociatedWithReplacementBrand.find(replacementBrandDomain => getSubdomainFromObj(replacementBrandDomain) === getSubdomainFromObj(domainToReplace) && getTopLevelDomain(replacementBrandDomain) === getTopLevelDomain(domainToReplace)) || null;
  }
  return null;
};
export const getComputedReplacementTrackedDomain = (domainToReplace, brandToReplace, replacementBrand) => domainToReplace.replace(`.${brandToReplace}.`, `.${replacementBrand}.`);
export function getBrandNameWithSuffix(brandName, suffix) {
  return `${brandName}.${suffix}`;
}
export function getBrandNameWithSubdomainAndSuffix(brandName, suffix, subdomain) {
  return `${subdomain}.${brandName}.${suffix}`;
}
export function getFullDomainName(brandName, suffix, subdomain) {
  return subdomain ? getBrandNameWithSubdomainAndSuffix(brandName, suffix, subdomain) : getBrandNameWithSuffix(brandName, suffix);
}

// New brand domain inherits subdomain and suffix from old domain
export const getReplacementBrandDomainWithInheritedFields = (domainToReplace, replacementBrand) => {
  const inheritedSubdomain = getSubdomainFromObj(domainToReplace);
  const inheritedSuffix = domainToReplace.get('publicSuffix');
  return getFullDomainName(replacementBrand, inheritedSuffix, inheritedSubdomain);
};
export const getReplacementBrandDomainWithSelectedFields = (domainToReplace, replacementBrand, subdomainNames, suffixes) => {
  const oldDomainName = domainToReplace.get('domain');
  return getFullDomainName(replacementBrand, suffixes.get(oldDomainName), subdomainNames.get(oldDomainName));
};
export const getDomainSuffix = domain => {
  return domain.substring(domain.lastIndexOf('.') + 1);
};