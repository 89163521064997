'use es6';

import { createSelector } from 'reselect';
import { RequestStatus, ListingState } from '../../Constants';
import { getIsEmailSendingDomainConnected } from '../../utils/domains/Domains';
import { selectIsUngatedForSpfAndDmarc } from '../Auth';
import DmarcCheckResultStatuses from '../../enums/domains/DmarcCheckResultStatuses';
export const getDomainIds = state => state.emailSendingDomains.get('domainIds');
export const getDomainObjects = state => state.emailSendingDomains.get('domains');
export const getEmailSendingDomainPunycodeName = domainObj => domainObj.get('domain');
export const isFullyAuthenticated = domainObj => {
  const state = domainObj.get('emailSendingDomainState');
  return state.get('isDmarcConnected') && state.get('isDkimConnected');
};
export const getDmarcStatus = domaninObj => {
  if (!domaninObj || !domaninObj.get('validatedConfigurations')) {
    return DmarcCheckResultStatuses.ERROR_UNKNOWN;
  }
  return domaninObj.get('validatedConfigurations').map(v => v.get('dmarcValidationResult')).reduce((_, current) => current || DmarcCheckResultStatuses.ERROR_UNKNOWN);
};
export const hasAtLeastOneDnsError = domainObj => {
  const state = domainObj.get('emailSendingDomainState');
  return !state.get('isDmarcConnected') || !state.get('isDkimConnected') || !state.get('isSpfConnected');
};
export const getEmailSendingDomainUnicodeName = domainObj => domainObj.get('unicodeDomain');
export const getRequestStatus = state => state.emailSendingDomains.get('emailRequestStatus');
export const getSpfRecordsRequestStatus = state => state.emailSendingDomains.get('spfRecordsRequestStatus');
export const getSpfRecords = state => state.emailSendingDomains.get('spfRecords');
const getMaxNumberOfEmailSendingDomainsForPortal = state => state.emailSendingDomains.get('maxNumberOfEmailSendingDomainsForPortal');
const getConnectedEmailSendingDomainsCount = createSelector([getDomainObjects, selectIsUngatedForSpfAndDmarc], (domains, isUngatedForSpfAndDmarc) => domains.count(domain => getIsEmailSendingDomainConnected({
  domain,
  isUngatedForSpfAndDmarc
})));
export const getIsUnderMaxNumberOfEmailSendingDomains = createSelector([getConnectedEmailSendingDomainsCount, getMaxNumberOfEmailSendingDomainsForPortal], (connectedEmailSendingDomainsCount, maxNumberOfEmailSendingDomainsForPortal) => connectedEmailSendingDomainsCount < maxNumberOfEmailSendingDomainsForPortal);
export const getEmailDomainsListingState = createSelector([getDomainObjects, getRequestStatus], (domains, requestStatus) => {
  if (requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNINITIALIZED) {
    return ListingState.LOADING;
  } else if (requestStatus === RequestStatus.FAILED) {
    return ListingState.ERROR;
  }
  if (domains.size === 0) {
    return ListingState.EMPTY;
  }
  return ListingState.RETRIEVED;
});
export const getEmailSendingDomainsList = createSelector([getDomainObjects, getDomainIds], (domains, domainIds) => domainIds.map(id => domains.get(id)));
export const getInvalidEmailSendingDomains = createSelector([getEmailSendingDomainsList, selectIsUngatedForSpfAndDmarc], (emailSendingDomains, isUngatedForSpfAndDmarc) => {
  return emailSendingDomains.filter(domain => !getIsEmailSendingDomainConnected({
    domain,
    isUngatedForSpfAndDmarc
  }));
});