import Immutable from 'immutable';
import { ALL_USERS_FILTER_VALUE } from '../../Constants';
export function getHubUsersMapFromList(usersList) {
  return usersList.reduce((accumulator, user) => Object.assign({}, accumulator, {
    [user.id]: user
  }), {});
}
export function formatOptionsForFilter(userList) {
  if (userList.size > 0) {
    return userList.filter(user => user !== undefined).map(user => ({
      text: `${user.firstName} ${user.lastName}`,
      value: user.id
    }));
  }
  return Immutable.List();
}
export const addFilterDefaultOption = (options, defaultText) => {
  return options.unshift({
    text: defaultText,
    value: ALL_USERS_FILTER_VALUE
  });
};