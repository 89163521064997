/* hs-eslint ignored failing-rules */

'use es6';

import { FETCH_BUSINESS_UNITS_ATTEMPTED, FETCH_BUSINESS_UNITS_SUCCEEDED, FETCH_BUSINESS_UNITS_FAILED } from '../ActionTypes';
import * as BusinessUnitsApi from '../../api/domains/BusinessUnits';
const getFetchBusinessUnitsAttemptedAction = () => ({
  type: FETCH_BUSINESS_UNITS_ATTEMPTED
});
const getFetchBusinessUnitsSucceededAction = businessUnits => ({
  type: FETCH_BUSINESS_UNITS_SUCCEEDED,
  businessUnits
});
const getFetchBusinessUnitsFailedAction = () => ({
  type: FETCH_BUSINESS_UNITS_FAILED
});
export const fetchBusinessUnits = () => dispatch => {
  dispatch(getFetchBusinessUnitsAttemptedAction());
  BusinessUnitsApi.fetchBusinessUnits().then(businessUnits => {
    dispatch(getFetchBusinessUnitsSucceededAction(businessUnits));
  }, () => {
    dispatch(getFetchBusinessUnitsFailedAction());
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};