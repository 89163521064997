'use es6';

import Immutable from 'immutable';
import _ from 'underscore';
import { UsageTypesKey, DomainType, RequestStatus, ValidationState, DomainConnectChangeTypes, IndicatorTypes, DomainUsageTypeToUsageTypesMap, UsageTypeIndices, PageBuilderDomainUsageTypes, DomainSetupTaskCategories, DomainUsageTypes, UsageTypeToDomainUsageTypesMap, DomainUsageTypeDisplayOrder } from '../../Constants';
import { addUsageType, addUsageTypes, getSecondaryUsageTypes, removeDomainUsageType, getHostedDomainUsageTypes } from './UsageTypes';
import getIsEveryDnsRecordValid from './getIsEveryDnsRecordValid';
import { calculateErrorCountOfRecords } from './domainWizard';
export function removeDomainById(domains, domainId) {
  if (domains.get(domainId)) {
    return domains.delete(domainId);
  }
  return domains;
}
export function getTrackedDomainName(domain) {
  return domain.get('domain');
}
export function extractSubdomainFromFullDomainName(domainName, apexDomain) {
  return domainName.substring(0, domainName.indexOf(apexDomain) - 1);
}
export function getSubdomainList(domainIds, apexDomain, domains) {
  return domainIds.map(id => {
    return extractSubdomainFromFullDomainName(domains.getIn([id, 'domain']), apexDomain);
  });
}
export function getOwnershipVerificationHosts(domainIds, apexDomain, domains) {
  const domainIdsPendingOwnershipValidation = getDomainIdsPendingOwnershipValidation(domains, domainIds);
  const hosts = new Set();
  domainIdsPendingOwnershipValidation.forEach(id => {
    const subdomain = extractSubdomainFromFullDomainName(domains.getIn([id, 'domain']), apexDomain);
    if (subdomain.length > 0) {
      hosts.add(`hsverify.${subdomain}`);
    } else {
      hosts.add(`hsverify`);
    }
  });
  return Immutable.List(hosts);
}

/**
 * Creates map of unique full domain names --> hosted domain usage types (one to many)
 *
 * @param brandName - string brand name, does not include subdomain or suffix
 * @param subdomainNames - subdomain map of { DomainUsageType: string }
 * @param suffixes - suffix map of { DomainUsageType: string }
 *
 */
export function getUniqueDomainNamesToHostedUsageTypesMap({
  brandName,
  subdomainNames,
  suffixes
}) {
  const domainMap = {};
  let fullDomainName = '';
  subdomainNames.forEach((subdomainName, domainUsageType) => {
    fullDomainName = subdomainName ? `${subdomainName}.${brandName}.${suffixes.get(domainUsageType)}` : `${brandName}.${suffixes.get(domainUsageType)}`;
    if (domainMap[fullDomainName]) {
      domainMap[fullDomainName].push(domainUsageType);
      return;
    }
    domainMap[fullDomainName] = [domainUsageType];
  });
  return domainMap;
}

/**
 * Prepares a request body for the "create setup task" endpoint
 * (cos-domains/v1/domain-setup-tasks/for-domains)
 *
 * Called when a user has finished picking + verifying their new domains
 * and before advancing to the Hosting Setup step
 * @param category - one of DomainSetupTaskCategories
 * @param brandName - string brand, without either subdomain or suffix
 * @param subdomainNames - map of { DomainUsageType: string }
 * @param suffixes - map of { DomainUsageType: string }
 * @param primaryLanguages - map of { DomainUsageType: string }
 * @param automaticRedirectDomain - an automatically created redirect domain, if applicable
 * @param automaticRedirectDestination - the destination of the automatically created redirect domain, if applicable
 * @returns formatted JSON payload
 */
export function createAddDomainSetupTaskPayload({
  category,
  brandName,
  subdomainNames,
  suffixes,
  primaryLanguages,
  automaticRedirectDomain,
  automaticRedirectDestination
}) {
  const requestPayload = {
    domains: [],
    category,
    apexRedirectDomains: []
  };
  const uniqueDomainNamesToHostedUsageTypeMap = getUniqueDomainNamesToHostedUsageTypesMap({
    brandName,
    subdomainNames,
    suffixes
  });
  Object.entries(uniqueDomainNamesToHostedUsageTypeMap).forEach(([uniqueFullDomainName, hostedDomainUsageTypes]) => {
    const domainObj = {
      domain: uniqueFullDomainName,
      usageTypes: []
    };
    let setupLanguage = undefined;
    hostedDomainUsageTypes.forEach(domainUsageType => {
      setupLanguage = primaryLanguages.get(domainUsageType);
      const isPrimary = !!category && category === DomainSetupTaskCategories.PRIMARY_CREATE;
      const usageTypeIndex = isPrimary ? UsageTypeIndices.PRIMARY_FOR : UsageTypeIndices.USED_FOR;
      const usageType = DomainUsageTypeToUsageTypesMap[domainUsageType][usageTypeIndex];
      domainObj.usageTypes.push(usageType);
    });
    domainObj.setUpLanguage = setupLanguage;
    requestPayload.domains.push(domainObj);
    if (automaticRedirectDomain !== undefined && automaticRedirectDestination !== undefined && uniqueFullDomainName === automaticRedirectDestination) {
      requestPayload.apexRedirectDomains.push({
        domain: automaticRedirectDomain,
        secondaryToDomain: automaticRedirectDestination,
        setUpLanguage: setupLanguage
      });
    }
  });
  return requestPayload;
}
export function getNewDomainsAndNewDomainIdsFromResponse(resp) {
  const automaticRedirectDomains = [];
  const siblingTask = resp.get('siblingTask');
  if (siblingTask) {
    automaticRedirectDomains.push(...siblingTask.get('domains'));
  }
  const newDomainIds = resp.get('domains').map(domain => getDomainIdAsString(domain)).concat(automaticRedirectDomains.map(domain => getDomainIdAsString(domain)));
  const newDomains = resp.get('domains').concat(automaticRedirectDomains);
  return {
    newDomains,
    newDomainIds
  };
}
export function isDkimRecordValid(record) {
  return record.get('result').toUpperCase().indexOf('ERROR') !== 0;
}
export function isContentHostingDomainResolving(domain) {
  if (domain) {
    return (!!domain.get('isDnsCorrect') || !!domain.get('isUsedForReverseProxy')) && !!domain.get('isResolving');
  }
  return false;
}
export function isHsSitesDomain(domain) {
  if (domain) {
    return domain.get('isInternalDomain') && domain.get('apexDomain', '').includes('hs-sites');
  }
  return false;
}
export function isHubSpotPageBuilderDomain(domain) {
  if (domain) {
    return domain.get('isPageBuilderDomain') || domain.get('isInternalDomain') && domain.get('apexDomain', '').includes('hubspotpagebuilder');
  }
  return false;
}

// Checks if DomainUsageType supports PageBuilder hosting and if the user/portal has hs-sites scope
// (You currently cannot have both hs-sites and pagebuilder for the same usage type)
export function getIsPageBuilderDomainAvailable(domainUsageType, domainUsageTypesWithHsSitesWriteScope) {
  const canDomainUsageTypeHostPageBuilder = PageBuilderDomainUsageTypes.includes(domainUsageType);
  const shouldPortalUseHsSitesInstead = !!domainUsageTypesWithHsSitesWriteScope && domainUsageTypesWithHsSitesWriteScope.includes(domainUsageType);
  return canDomainUsageTypeHostPageBuilder && !shouldPortalUseHsSitesInstead;
}
export function isDomainInternalOrHasBrandId(domain) {
  return domain.get('brandId') || domain.get('isInternalDomain');
}
export function isWwwSubdomain(domain) {
  return extractSubdomainFromFullDomainName(domain.get('domain'), domain.get('apexDomain')) === 'www';
}
export function isApexDomain(domain) {
  return !!domain && domain.get('domain') === domain.get('apexDomain');
}
export function getIsAnyNewDomainApex(domains, newDomainIds) {
  return newDomainIds.some(id => isApexDomain(domains.get(id)));
}
export function getIsAnyNewDomainSubdomain(domains, newDomainIds) {
  return newDomainIds.some(id => !isApexDomain(domains.get(id)));
}
export function getCosDomainStatusIndicatorType(domain) {
  if (!domain.get('isPendingOwnershipValidation')) {
    if (isContentHostingDomainResolving(domain) || domain.get('isUsedForReverseProxy')) {
      return IndicatorTypes.SUCCESS;
    }
    if (domain.get('manuallyMarkedAsResolving')) {
      return IndicatorTypes.WARNING;
    }
  }
  return IndicatorTypes.DISABLED;
}
export const getIsEmailSendingDomainFullyAuthenticated = domain => {
  const emailSendingDomainState = domain.get('emailSendingDomainState') || Immutable.Map({});
  return emailSendingDomainState.get('isDkimConnected') && emailSendingDomainState.get('isSpfConnected') && emailSendingDomainState.get('isDmarcConnected');
};
export const getIsEmailSendingDomainConnected = ({
  domain,
  isUngatedForSpfAndDmarc
}) => {
  if (isUngatedForSpfAndDmarc) {
    return getIsEmailSendingDomainFullyAuthenticated(domain);
  }
  return domain.getIn(['emailSendingDomainState', 'connected']);
};
export function areAllContentHostingTaskDomainsVerified(domains, newDomainIds) {
  return newDomainIds.every(id => {
    const domain = domains.get(id);
    return isContentHostingDomainResolving(domain);
  });
}

/**
 * Checks for:
 * 1. All new domains are resolving
 * 2. All new domains' CNAME and A records are correct
 * 3. Any additional DNS records are also correct (e.g. DNS provider specific hostname verification TXT records)
 */
export function getAreAllNewHostingDomainsAndDnsRecordsVerified(domains, newDomainIds, dnsRecords) {
  const areAllNewHostingDomainsVerified = areAllContentHostingTaskDomainsVerified(domains, newDomainIds);
  const areAllDnsRecordsCorrect = getIsEveryDnsRecordValid(dnsRecords) && calculateErrorCountOfRecords(dnsRecords) === 0;
  return areAllNewHostingDomainsVerified && areAllDnsRecordsCorrect;
}
export function isDomainPendingOwnershipValidation(domains, id) {
  return domains.getIn([id, 'isPendingOwnershipValidation'], false);
}
export function getAreAnyNewDomainsPendingOwnershipValidation(domains, newDomainIds) {
  return newDomainIds.some(id => isDomainPendingOwnershipValidation(domains, id));
}
export function getDomainIdsPendingOwnershipValidation(domains, domainIds) {
  return domainIds.filter(id => isDomainPendingOwnershipValidation(domains, id));
}

/**
 *
 * @param {*} subdomain
 * @param {*} validationState
 * @returns true if is either valid subdomain or apex domain
 */
export function isSubdomainValid(subdomain, validationState) {
  return subdomain && validationState === ValidationState.VALID_FULL_URL || validationState === ValidationState.VALID_APEX_DOMAIN;
}

/**
 *
 * @param {*} subdomainNames
 * @param {*} subdomainValidationStates
 * @returns true if all rows are valid subdomains or apex dommains
 */
export function areAllSubdomainsValid({
  subdomainNames,
  subdomainValidationStates
}) {
  return subdomainValidationStates.filter((validationState, domainRowKey) => {
    return isSubdomainValid(subdomainNames.get(domainRowKey), validationState);
  }).size === subdomainNames.keySeq().size;
}
export function areDomainAdditionAndLookupRequestsPending({
  addDomainsRequestStatus,
  dnsProviderRequestStatus
}) {
  return addDomainsRequestStatus === RequestStatus.PENDING || dnsProviderRequestStatus === RequestStatus.PENDING;
}
export function didAddDomainSetupRequestsComplete(addDomainsRequestStatus, dnsProviderRequestStatus) {
  const requestReturnStatuses = [RequestStatus.SUCCEEDED, RequestStatus.FAILED];
  return requestReturnStatuses.includes(addDomainsRequestStatus) && requestReturnStatuses.includes(dnsProviderRequestStatus);
}
export function didAddDomainSetupRequestsSucceed(addDomainsRequestStatus, dnsProviderRequestStatus) {
  const requestReturnStatuses = [RequestStatus.SUCCEEDED, RequestStatus.FAILED];
  return addDomainsRequestStatus === RequestStatus.SUCCEEDED && requestReturnStatuses.includes(dnsProviderRequestStatus);
}
export function didContinueDomainSetupRequestsComplete(taskDomainsRequestStatus, dnsProviderRequestStatus) {
  const requestReturnStatuses = [RequestStatus.SUCCEEDED, RequestStatus.FAILED];
  return taskDomainsRequestStatus === RequestStatus.SUCCEEDED && requestReturnStatuses.includes(dnsProviderRequestStatus) || taskDomainsRequestStatus === RequestStatus.FAILED;
}
export function didDomainSetupComplete(isSetupComplete, taskDomainsRequestStatus, dnsProviderRequestStatus) {
  return isSetupComplete && taskDomainsRequestStatus === RequestStatus.UNINITIALIZED && dnsProviderRequestStatus === RequestStatus.UNINITIALIZED;
}

/**
 * A domainObj is a valid secondary domain if has at least one
 * secondary usage type it isn't already a primary for
 */
export function isSecondaryDomain(domainObj) {
  if (!domainObj.get('isUsedForAnyContentType') || !domainObj.get('usageTypes') || !getSecondaryUsageTypes(domainObj)) {
    return false;
  }
  const hasSecondaryWithoutEquivalentPrimary = getSecondaryUsageTypes(domainObj).some(secondaryUsageType => {
    const domainUsageType = UsageTypeToDomainUsageTypesMap[secondaryUsageType];
    const equivalentPrimaryUsageType = DomainUsageTypeToUsageTypesMap[domainUsageType][UsageTypeIndices.PRIMARY_FOR];
    return !domainObj.get('usageTypes').includes(equivalentPrimaryUsageType);
  });
  return hasSecondaryWithoutEquivalentPrimary;
}
export const getWasRedirectDomain = domain => !domain.get('isUsedForAnyContentType') && !!domain.get('secondaryToDomain');
export function getIsRedirectDomain(domain) {
  return getWasRedirectDomain(domain) && !isHubSpotPageBuilderDomain(domain);
}
export function getIsDowngradedDomain(domain) {
  return isHubSpotPageBuilderDomain(domain) && getWasRedirectDomain(domain) || !domain.get('isUsedForAnyContentType') && !domain.get('secondaryToDomain');
}
export function isIncompleteSetupDomain(domain, setupTasks) {
  if (!domain || !setupTasks) {
    return false;
  }
  return !domain.get('isSetupComplete') && setupTasks.has(domain.get('setupTaskId'));
}
export function getCosDomainTypePathParamForContinueSetup(domain, primarySetupTasks, replaceBrandSetupTasks) {
  if (domain.get('isAnyPrimary') || isIncompleteSetupDomain(domain, primarySetupTasks)) {
    return DomainType.PRIMARY;
  }
  if (isSecondaryDomain(domain)) {
    return DomainType.SECONDARY;
  }
  if (isIncompleteSetupDomain(domain, replaceBrandSetupTasks)) {
    return DomainType.REPLACE;
  }
  return DomainType.REDIRECT;
}
export function getRootDomainName(isApex, domainName) {
  return isApex ? domainName : domainName.substring(domainName.indexOf('.') + 1);
}
export function parseDomainNameFromEmailAddress(domainNameOrEmailAddress) {
  if (domainNameOrEmailAddress.indexOf('@') > -1) {
    return domainNameOrEmailAddress.substring(domainNameOrEmailAddress.indexOf('@') + 1);
  }
  return domainNameOrEmailAddress;
}
export function isSslEnabledForDomain(domain) {
  return domain.get('isSslEnabled');
}
export function didSubmitApexDomain(domainSubmissionStatus) {
  return domainSubmissionStatus === ValidationState.VALID_APEX_DOMAIN;
}
export function convertDomainsListToMap(domains, domainNameAccessor) {
  return domains.reduce((prev, domainObj) => {
    return prev.set(domainNameAccessor(domainObj), domainObj);
  }, Immutable.Map({}));
}
export function getDomainIdAsString(domain) {
  return domain.get('id').toString();
}
export function getDomainsWithUpdatedTaskDomains(requestDomains, stateDomains) {
  return stateDomains.map((stateDomain, id) => {
    let domain = stateDomain;
    requestDomains.forEach(requestDomain => {
      if (getDomainIdAsString(requestDomain) === id) {
        domain = requestDomain;
      }
    });
    return domain;
  });
}
export function didRequestSucceedOrFail(requestStatus) {
  return requestStatus === RequestStatus.SUCCEEDED || requestStatus === RequestStatus.FAILED;
}
export function getDoesDomainHostMoreThanOneSupportedUsageType(domain, supportedDomainUsageTypes) {
  const hostedDomainUsageTypes = getHostedDomainUsageTypes(domain);
  return hostedDomainUsageTypes.filter(domainUsageType => supportedDomainUsageTypes.includes(domainUsageType)).length > 1;
}
export function getNumDomainsInAddDomainPayload(domainObj) {
  if (domainObj.domains) {
    return domainObj.domains.length;
  }
  return 1;
}
export function getRemoveContentTypeRequestPayload(domainId, domainUsageType, currentUsageTypes) {
  const updatedUsageTypes = removeDomainUsageType(domainUsageType, currentUsageTypes);
  return Immutable.Map({
    id: domainId,
    usageTypes: updatedUsageTypes
  });
}

// Assumption: Always replacing PRIMARY domain, currently do not support secondary
export function updateReplacementDomainWithUsageTypes(updatedReplacementDomain, domainUsageType, domainUsageTypesWithCustomDomainWriteScope, portalHasMultiDomainHostingScope = false) {
  const existingUsageTypes = updatedReplacementDomain.get('usageTypes');
  const primaryUsageType = DomainUsageTypeToUsageTypesMap[domainUsageType][UsageTypeIndices.PRIMARY_FOR];
  const allInScopePrimaryUsageTypes = domainUsageTypesWithCustomDomainWriteScope.map(inScopeDomainUsageType => DomainUsageTypeToUsageTypesMap[inScopeDomainUsageType][UsageTypeIndices.PRIMARY_FOR]);

  // If portal does not have multi domain hosting scope,
  // replacement primary domain must host all scoped usage types
  const updatedUsageTypes = portalHasMultiDomainHostingScope ? addUsageType(primaryUsageType, existingUsageTypes) : addUsageTypes(allInScopePrimaryUsageTypes, existingUsageTypes);
  return updatedReplacementDomain.set(UsageTypesKey, updatedUsageTypes);
}
export function getFieldsForReplaceRequest(domainObj) {
  const requestFields = ['id', 'secondaryToDomain', 'usageTypes'];
  return _.pick(domainObj, requestFields);
}

// Note: currentContentHostingTypes contains "setupBlog: true" formatted entries
export function getUpdateContentTypesRequestPayload({
  stagedDomainUsageTypes,
  domain,
  isUpdatingPrimaryContentType = false
}) {
  const updatedDomainObject = {
    id: getDomainIdAsString(domain),
    secondaryToDomain: ''
  };
  const usageTypeIndex = isUpdatingPrimaryContentType ? UsageTypeIndices.PRIMARY_FOR : UsageTypeIndices.USED_FOR;
  const stagedUsageTypes = stagedDomainUsageTypes.map(domainUsageType => DomainUsageTypeToUsageTypesMap[domainUsageType][usageTypeIndex]);

  // Creates superset of staged selections + existing usage types
  // addUsageTypes util will enforce usage types business logic rules
  // e.g. no duplicates, primary takes precendece over secondary, etc.
  const updatedUsageTypes = addUsageTypes(stagedUsageTypes, domain.get('usageTypes'));
  updatedDomainObject[UsageTypesKey] = updatedUsageTypes;
  return Immutable.Map(updatedDomainObject);
}
export function arePortalDomainsAlreadyFetched(domains) {
  return !!domains.size;
}
export function getDomainValidationErrorType(responseJSON) {
  return responseJSON ? responseJSON.errorType : ValidationState.UNKNOWN;
}
export function getDomainValidationSuffix(responseJSON) {
  return responseJSON && responseJSON.errorTokens && responseJSON.errorTokens.possiblePublicSuffix && responseJSON.errorTokens.possiblePublicSuffix[0];
}
export function getSeparatedCnameAndTxtRecords(domain) {
  return {
    cnameRecords: domain.get('dkimRecords').filter(record => record.getIn(['dkimRecord', 'recordType']) === 'CNAME'),
    txtRecords: domain.get('dkimRecords').filter(record => record.getIn(['dkimRecord', 'recordType']) !== 'CNAME')
  };
}
export function getTaskDomainsSupportingSsl(domains, newContentHostingDomainIds) {
  return newContentHostingDomainIds.filter(domainId => domains.getIn([domainId, 'setupInfo', 'supportsSslExternally'])).map(domainId => domains.get(domainId));
}
export function getListOfDomainTypePickerButtons(isNewPortal, hasEmailAccessScope, supportedDomainUsageTypesForSecondary, portalCanSetupDedicatedIP) {
  const buttonList = [DomainType.PRIMARY];
  if (!isNewPortal && !!supportedDomainUsageTypesForSecondary.length) {
    buttonList.push(DomainType.SECONDARY);
  }
  if (!isNewPortal) {
    buttonList.push(DomainType.REDIRECT);
  }
  if (hasEmailAccessScope) {
    buttonList.push(DomainType.EMAIL);
  }
  if (portalCanSetupDedicatedIP) {
    buttonList.push(DomainType.DEDICATED_IP);
  }
  return buttonList;
}
export function generateMatchAreaNumericalString() {
  return Math.floor(1000000 + Math.random() * 9000000).toString();
}
export function convertSetupTasksListToMap(setupTasks) {
  return setupTasks.reduce((prev, setupTaskObj) => {
    return prev.set(setupTaskObj.get('id'), setupTaskObj);
  }, Immutable.Map({}));
}

// Given a collection of primary domains,
// finds first domain obj that is PRIMARY_FOR desired DomainUsageType
export function findPrimaryDomainForDomainUsageType(primaryDomains, domainUsageType) {
  const primaryUsageType = DomainUsageTypeToUsageTypesMap[domainUsageType][UsageTypeIndices.PRIMARY_FOR];
  return primaryDomains.find(domainObj => domainObj.get('usageTypes').includes(primaryUsageType));
}
export function updatePrimaryDomainUsageTypeAvailabilityAfterRemoval(primaryDomainUsageTypeAvailability, domainType, domainUsageType) {
  if (domainType === DomainType.PRIMARY && domainUsageType) {
    return primaryDomainUsageTypeAvailability.push(domainUsageType);
  }
  return primaryDomainUsageTypeAvailability;
}

// Given a collection of incomplete setup primary domains,
// finds first domain obj that is USED_FOR desired DomainUsageType
export function findIncompleteSetupPrimaryDomainForDomainUsageType(incompleteSetupPrimaryDomains, domainUsageType) {
  const secondaryUsageType = DomainUsageTypeToUsageTypesMap[domainUsageType][UsageTypeIndices.USED_FOR];
  return incompleteSetupPrimaryDomains.find(domainObj => {
    return domainObj.get('usageTypes').includes(secondaryUsageType);
  });
}
export function getSpfRecordsSet(requirements) {
  return Immutable.Set(requirements.filter(requirementsObj => requirementsObj.getIn(['spfInclude', 'value'])).map(requirementsObj => requirementsObj.getIn(['spfInclude', 'value'])));
}
export function getSpfRecordsString(spfRecordsSet) {
  return spfRecordsSet.map(spf => spf && `include:${spf}`).join('\n');
}
export const getIsHostingDomain = domain => isSecondaryDomain(domain) || domain.get('isAnyPrimary');
export const getSslSettings = requireHttps => ({
  isSslOnly: requireHttps
});
export function getRedirectToOptions(connectedHostingDomains) {
  return connectedHostingDomains.map(domainOption => ({
    text: domainOption.get('domain'),
    value: domainOption.get('domain')
  })).toJS();
}
export function getApexDomainValidationState(isApex) {
  return isApex ? ValidationState.VALID_APEX_DOMAIN : ValidationState.VALID_FULL_URL;
}
export function getDomainRowKeyListForMatchingSubdomainsAndSuffixes(subdomainNames, suffixes, domainRowKey, newDomainName, newSuffix) {
  const suffix = newSuffix || suffixes.get(domainRowKey);
  const subdomainName = newDomainName || subdomainNames.get(domainRowKey);
  const getHasMatchingSubdomainAndSuffix = domainRowKeyToCompare => {
    const currentSubdomainName = domainRowKey === domainRowKeyToCompare ? subdomainName : subdomainNames.get(domainRowKeyToCompare);
    const currentSuffix = domainRowKey === domainRowKeyToCompare ? suffix : suffixes.get(domainRowKeyToCompare);
    return currentSubdomainName === subdomainName && currentSuffix === suffix;
  };
  const canSortDomainRowKeys = subdomainNames.keySeq().every(mapKey => mapKey in DomainUsageTypes);
  if (canSortDomainRowKeys) {
    return DomainUsageTypeDisplayOrder.filter(possibleKey => subdomainNames.has(possibleKey)).filter(getHasMatchingSubdomainAndSuffix);
  }
  return [...subdomainNames.keySeq()].filter(getHasMatchingSubdomainAndSuffix);
}

/**
 *
 * Creates a brand new map with all keys set to the same default value
 *
 * @param {collection} keys - unique list of keys (e.g. usage types, domains to replace, etc.)
 * @param {string} value - default suffix, subdomain, validation state, etc.
 */
export function initMapWithDefaultValue(keys, value) {
  const mappedTuples = keys.map(k => [k, value]);
  return Immutable.Map(mappedTuples);
}

// Content hosting domains can either be an apex/root domain or have a subdomain
// Assumes we've already checked this domainId is not an Email Sending domain
export function getContentHostingChangeType(domainId, apexDomain, domains) {
  const possibleSubdomain = extractSubdomainFromFullDomainName(domains.getIn([domainId, 'domain']), apexDomain);
  return possibleSubdomain ? DomainConnectChangeTypes.SUBDOMAIN : DomainConnectChangeTypes.APEX_DOMAIN;
}