'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import DEFAULT_STATE from '../../data/domains/Brands';
const addBrand = (state, newBrand) => {
  let brands = state.get('brands');
  const hasBrand = brands.find(brand => brand.get('name') === newBrand.get('name'));
  if (!hasBrand) {
    brands = brands.push(newBrand);
  }
  return {
    brands,
    portalBrandCount: brands.size
  };
};
export default function Brands(state = DEFAULT_STATE, action) {
  const {
    type,
    portalBrandCount,
    allowedBrandCount,
    brands,
    addedBrand,
    deletedBrandName
  } = action;
  switch (type) {
    case ActionTypes.ADD_BRAND_ATTEMPTED:
      return state.merge({
        addBrandRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.ADD_BRAND_SUCCEEDED:
      return state.merge(Object.assign({
        addBrandRequestStatus: RequestStatus.SUCCEEDED
      }, addBrand(state, addedBrand)));
    case ActionTypes.ADD_BRAND_FAILED:
      return state.merge({
        addBrandRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_BRANDS_ATTEMPTED:
      return state.merge({
        brandsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_BRANDS_FAILED:
      return state.merge({
        brandsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_BRANDS_SUCCEEDED:
      return state.merge({
        allowedBrandCount,
        portalBrandCount,
        brands,
        brandsRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.DELETE_BRAND_ATTEMPTED:
      return state.merge({
        deleteBrandRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.DELETE_BRAND_FAILED:
      return state.merge({
        deleteBrandRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.DELETE_BRAND_SUCCEEDED:
      return state.merge({
        deleteBrandRequestStatus: RequestStatus.SUCCEEDED,
        portalBrandCount: state.get('portalBrandCount') - 1,
        brands: state.get('brands').filter(brandObj => brandObj.get('name') !== deletedBrandName)
      });
    case ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_ATTEMPTED:
      return state.merge({
        updateBrandBusinessUnitRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_SUCCEEDED:
      return state.merge({
        updateBrandBusinessUnitRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_FAILED:
      return state.merge({
        updateBrandBusinessUnitRequestStatus: RequestStatus.FAILED
      });
    default:
      return state;
  }
}