'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import { convertDomainsListToMap, removeDomainById, getSpfRecordsSet } from '../../utils/domains/Domains';
import { getEmailSendingDomainPunycodeName } from '../../selectors/domains/EmailSendingDomains';
import DEFAULT_STATE from '../../data/domains/EmailSendingDomains';
export default function EmailSendingDomains(state = DEFAULT_STATE, action) {
  const {
    type,
    emailDomain,
    domains,
    domainName,
    requirements,
    maxNumberOfEmailSendingDomainsForPortal
  } = action;
  switch (type) {
    case ActionTypes.FETCH_EMAIL_DOMAINS_ATTEMPTED:
      return state.merge({
        emailRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_EMAIL_DOMAINS_FAILED:
      return state.merge({
        emailRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_EMAIL_DOMAINS_SUCCEEDED:
      return state.merge({
        domainIds: domains.map(domainObj => getEmailSendingDomainPunycodeName(domainObj)),
        domains: convertDomainsListToMap(domains, getEmailSendingDomainPunycodeName),
        emailRequestStatus: RequestStatus.SUCCEEDED,
        maxNumberOfEmailSendingDomainsForPortal
      });
    case ActionTypes.FETCH_SPF_RECORDS_ATTEMPTED:
      {
        return state.set('spfRecordsRequestStatus', RequestStatus.PENDING);
      }
    case ActionTypes.FETCH_SPF_RECORDS_FAILED:
      {
        return state.set('spfRecordsRequestStatus', RequestStatus.FAILED);
      }
    case ActionTypes.FETCH_SPF_RECORDS_SUCCEEDED:
      return state.merge({
        spfRecords: getSpfRecordsSet(requirements),
        spfRecordsRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.REMOVE_EMAIL_DOMAIN_SUCCEEDED:
      return state.merge({
        domainIds: state.get('domainIds').filter(id => id !== domainName),
        domains: removeDomainById(state.get('domains'), domainName)
      });
    case ActionTypes.ADD_EMAIL_DOMAIN_SUCCEEDED:
      if (state.get('domainIds').indexOf(getEmailSendingDomainPunycodeName(emailDomain)) === -1) {
        return state.merge({
          domainIds: state.get('domainIds').concat(getEmailSendingDomainPunycodeName(emailDomain)),
          domains: state.get('domains').set(getEmailSendingDomainPunycodeName(emailDomain), emailDomain)
        });
      }
      return state;
    default:
      return state;
  }
}