'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { FETCH_BUSINESS_UNITS_SUCCEEDED, FETCH_BUSINESS_UNITS_FAILED, FETCH_BUSINESS_UNITS_ATTEMPTED } from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
const BUSINESS_UNITS_EMPTY_STATE = ImmutableMap({
  businessUnits: List(),
  requestStatus: RequestStatus.UNINITIALIZED
});
export default function BusinessUnits(state = BUSINESS_UNITS_EMPTY_STATE, action) {
  const {
    type,
    businessUnits
  } = action;
  switch (type) {
    case FETCH_BUSINESS_UNITS_SUCCEEDED:
      {
        return state.merge({
          businessUnits,
          requestStatus: RequestStatus.SUCCEEDED
        });
      }
    case FETCH_BUSINESS_UNITS_ATTEMPTED:
      {
        return state.set('requestStatus', RequestStatus.PENDING);
      }
    case FETCH_BUSINESS_UNITS_FAILED:
      {
        return state.set('requestStatus', RequestStatus.FAILED);
      }
    default:
      return state;
  }
}