export const HELP_LINK = 'https://help.hubspot.com/';
export const SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/troubleshoot-ssl-certificate-errors';
export const CONNECT_DOMAIN_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/connect-a-domain-to-hubspot';
export const CONNECT_EMAIL_SENDING_DOMAIN_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/cos-general/how-to-connect-your-email-sending-domain';
export const UPDATE_DNS_RECORDS_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/domains-and-urls/update-your-dns-records';
export const CONNECT_DOMAIN_HOSTING_SETUP_LINK = `${CONNECT_DOMAIN_KNOWLEDGE_ARTICLE_LINK}#4-hosting-setup`;
export const CONNECT_EMAIL_SENDING_DOMAIN_VERIFY_URLS_LINK = `${CONNECT_EMAIL_SENDING_DOMAIN_KNOWLEDGE_ARTICLE_LINK}#verify-urls`;
export const ERROR_ISSUING_SSL_CERTIFICATE = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#there-was-a-problem-issuing-the-ssl-certificate-for-www-yourdomain-com`;
export const SSL_PROCESSING_LINK = `${CONNECT_DOMAIN_KNOWLEDGE_ARTICLE_LINK}#ssl`;
export const WHOIS_EMAIL_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#the-domain-does-not-have-a-whois-email`;
export const DOMAIN_IS_INVALID_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#the-domain-requested-for-validation-is-not-valid`;
export const UPDATE_CNAME_RECORD_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#the-ssl-certificate-for-www-yourdomain-com-couldn-t-be-validated`;
export const DOMAIN_UNSAFE_BY_GOOGLE_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#www-yourdomain-com-has-been-marked-as-potentially-unsafe-by-google`;
export const DOMAIN_UNSAFE_BY_PHISHTANK_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#www-yourdomain-com-has-been-marked-as-potentially-unsafe-by-phishtank`;
export const UNABLE_TO_VALIDATE_DOMAIN_LINK = `${SSL_ERRORS_KNOWLEDGE_ARTICLE_LINK}#we-were-unable-to-validate-www-yourdomain-com`;