export default function once(fn) {
  let didRun = false;
  let result;
  return function onced(...args) {
    if (!didRun) {
      didRun = true;
      result = fn(...args);
    }
    // Can be safely casted since `didRun` is a de-facto type guard but `ReturnType<Fn>` can be undefined too
    // Could be avoided if cache was an object
    return result;
  };
}