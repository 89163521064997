import { RequestStatus } from '../../Constants';
import { SslVerificationActions } from '../../actions/ActionTypes';
const DefaultState = {
  verifyDomainSslAndReturnIssueRequestStatus: RequestStatus.UNINITIALIZED
};
const SslVerification = (state = DefaultState, action) => {
  const {
    type,
    isSslValid,
    sslIssue,
    domainName
  } = action;
  switch (type) {
    case SslVerificationActions.initialize:
      return Object.assign({}, state, {
        verifyDomainSslAndReturnIssueRequestStatus: RequestStatus.PENDING
      });
    case SslVerificationActions.verificationSucceeded:
      return Object.assign({}, state, {
        domainName,
        verifyDomainSslAndReturnIssueRequestStatus: RequestStatus.SUCCEEDED,
        isSslValid,
        sslIssue,
        previousSslIssueType: state.sslIssue && state.sslIssue.type
      });
    case SslVerificationActions.verificationFailed:
      return Object.assign({}, state, {
        domainName,
        verifyDomainSslAndReturnIssueRequestStatus: RequestStatus.FAILED
      });
    case SslVerificationActions.clearState:
      return Object.assign({}, DefaultState);
    default:
      return state;
  }
};
export default SslVerification;