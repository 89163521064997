const truncate = (str = '', limit = 256) => {
  let truncated = str;
  if (truncated.length > limit) {
    truncated = truncated.substr(0, limit);
    truncated = `${truncated}[..]`;
  }
  return truncated;
};
export const convertToTrackingUrl = (url = '') => {
  let cleaned = url;
  try {
    // remove query strings & anchors
    cleaned = cleaned.split('?')[0].split('#')[0];

    // replace all ids & guids used as route params
    const parts = cleaned.split('/');
    const routeParts = parts.map(route => {
      if (/\d/.test(route)) {
        return '*';
      }
      return route;
    });
    cleaned = routeParts.join('/');

    // remove trailing slashes
    if (cleaned.charAt(cleaned.length - 1) === '/') {
      cleaned = cleaned.substr(0, cleaned.length - 1);
    }

    // truncate lengthy urls
    cleaned = truncate(cleaned, 256);
  } catch (err) {
    cleaned = 'parsing error';
  }
  return cleaned;
};