import { orderDomainUsageTypes } from './UsageTypes';
import { convertDomainsListToMap, getDomainIdAsString
//@ts-expect-error not migrated yet
} from './Domains';
function formatDashboardResponse(dashboardResponse) {
  const domains = dashboardResponse.get('domains');
  const domainsRequiringMigrationToDcv = dashboardResponse.get('domainsRequiringMigrationToDcv');
  const primaryDomainUsageTypeAvailability = dashboardResponse.get('primaryDomainUsageTypeAvailability');
  const supportedDomainUsageTypesForCustomDomain = dashboardResponse.get('supportedDomainUsageTypesForCustomDomain');
  const supportedDomainUsageTypeForHsSites = dashboardResponse.get('supportedDomainUsageTypeForHsSites');
  const supportedSecondaryDomainUsageTypes = dashboardResponse.get('supportedSecondaryDomainUsageTypes');
  const formattedData = {};
  if (domains) {
    formattedData.domainIds = domains.map(domainObj => getDomainIdAsString(domainObj));
    formattedData.domains = convertDomainsListToMap(domains, getDomainIdAsString);
  }
  if (domainsRequiringMigrationToDcv) {
    formattedData.domainsRequiringMigrationToDcv = domainsRequiringMigrationToDcv;
  }
  if (primaryDomainUsageTypeAvailability) {
    formattedData.primaryDomainUsageTypeAvailability = orderDomainUsageTypes(primaryDomainUsageTypeAvailability);
  }
  if (supportedDomainUsageTypesForCustomDomain) {
    formattedData.supportedDomainUsageTypesForCustomDomain = orderDomainUsageTypes(supportedDomainUsageTypesForCustomDomain);
  }
  if (supportedDomainUsageTypeForHsSites) {
    formattedData.supportedDomainUsageTypeForHsSites = orderDomainUsageTypes(supportedDomainUsageTypeForHsSites);
  }
  if (supportedSecondaryDomainUsageTypes) {
    formattedData.supportedSecondaryDomainUsageTypes = orderDomainUsageTypes(supportedSecondaryDomainUsageTypes);
  }
  return formattedData;
}
export default formatDashboardResponse;