const SUCCESS = 'SUCCESS';
const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
const STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_DKIM = 'STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_DKIM';
const STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF = 'STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF';
const ERROR_NO_DMARC = 'ERROR_NO_DMARC';
const ERROR_DNS_FAIL = 'ERROR_DNS_FAIL';
const STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF_AND_DKIM = 'STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF_AND_DKIM';
const MULTIPLE_DMARC_RECORDS = 'MULTIPLE_DMARC_RECORDS';
const INVALID_DMARC_MODE = 'INVALID_DMARC_MODE';
const INVALID_DMARC_POLICY = 'INVALID_DMARC_POLICY';
const INVALID_DMARC_PCT_FORMAT = 'INVALID_DMARC_PCT_FORMAT';
const INVALID_DMARC_EMAIL_ADDRESS = 'INVALID_DMARC_EMAIL_ADDRESS';
const MISSING_DMARC_REQUIRED_TAGS = 'MISSING_DMARC_REQUIRED_TAGS';
const DmarcCheckResultStatuses = {
  ERROR_NO_DMARC,
  STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF,
  STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_DKIM,
  ERROR_UNKNOWN,
  SUCCESS,
  ERROR_DNS_FAIL,
  STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF_AND_DKIM,
  MULTIPLE_DMARC_RECORDS,
  INVALID_DMARC_MODE,
  INVALID_DMARC_POLICY,
  INVALID_DMARC_PCT_FORMAT,
  INVALID_DMARC_EMAIL_ADDRESS,
  MISSING_DMARC_REQUIRED_TAGS
};
const invalidDmarcStates = [DmarcCheckResultStatuses.INVALID_DMARC_EMAIL_ADDRESS, DmarcCheckResultStatuses.INVALID_DMARC_MODE, DmarcCheckResultStatuses.INVALID_DMARC_PCT_FORMAT, DmarcCheckResultStatuses.INVALID_DMARC_POLICY, DmarcCheckResultStatuses.MULTIPLE_DMARC_RECORDS, DmarcCheckResultStatuses.MISSING_DMARC_REQUIRED_TAGS];
export default DmarcCheckResultStatuses;
const messagePrefix = 'settings-ui-domains.hostingSetupScreen.emailSendingDomainsTitle.dmarc.';
const kbUrlPrefix = 'https://knowledge.hubspot.com/marketing-email/troubleshoot-email-authentication-issues#';
export function getKbLinkForError(status) {
  switch (status) {
    case DmarcCheckResultStatuses.INVALID_DMARC_EMAIL_ADDRESS:
      return `${kbUrlPrefix}invalid-reporting-address`;
    case DmarcCheckResultStatuses.INVALID_DMARC_POLICY:
      return `${kbUrlPrefix}invalid-dmarc-policy-value`;
    case DmarcCheckResultStatuses.INVALID_DMARC_MODE:
      return `${kbUrlPrefix}invalid-dmarc-alignment-mode`;
    case DmarcCheckResultStatuses.INVALID_DMARC_PCT_FORMAT:
      return `${kbUrlPrefix}invalid-dmarc-percentage-format`;
    case DmarcCheckResultStatuses.MULTIPLE_DMARC_RECORDS:
      return `${kbUrlPrefix}multiple-records`;
    case DmarcCheckResultStatuses.MISSING_DMARC_REQUIRED_TAGS:
      return `${kbUrlPrefix}missing-required-dmarc-tags`;
    default:
      return null;
  }
}
export function getErrorDescription(status) {
  switch (status) {
    case DmarcCheckResultStatuses.INVALID_DMARC_EMAIL_ADDRESS:
      return `${messagePrefix}invalidEmailAddressErrorDescription_jsx`;
    case DmarcCheckResultStatuses.INVALID_DMARC_POLICY:
      return `${messagePrefix}invalidPolicyErrorDescription_jsx`;
    case DmarcCheckResultStatuses.INVALID_DMARC_MODE:
      return `${messagePrefix}invalidAlignmentErrorDescription_jsx`;
    case DmarcCheckResultStatuses.INVALID_DMARC_PCT_FORMAT:
      return `${messagePrefix}invalidPctErrorDescription_jsx`;
    case DmarcCheckResultStatuses.MULTIPLE_DMARC_RECORDS:
      return `${messagePrefix}multipleRecordsErrorDescription_jsx`;
    case DmarcCheckResultStatuses.MISSING_DMARC_REQUIRED_TAGS:
      return `${messagePrefix}missingTagsErrorDescription_jsx`;
    default:
      return null;
  }
}
export function getShortErrorMessage(status) {
  switch (status) {
    case DmarcCheckResultStatuses.INVALID_DMARC_EMAIL_ADDRESS:
      return `${messagePrefix}invalidEmailAddressError`;
    case DmarcCheckResultStatuses.INVALID_DMARC_POLICY:
      return `${messagePrefix}invalidPolicyError`;
    case DmarcCheckResultStatuses.INVALID_DMARC_MODE:
      return `${messagePrefix}invalidAlignmentError`;
    case DmarcCheckResultStatuses.INVALID_DMARC_PCT_FORMAT:
      return `${messagePrefix}invalidPctError`;
    case DmarcCheckResultStatuses.MULTIPLE_DMARC_RECORDS:
      return `${messagePrefix}multipleRecordsError`;
    case DmarcCheckResultStatuses.MISSING_DMARC_REQUIRED_TAGS:
      return `${messagePrefix}missingTagsError`;
    default:
      return null;
  }
}
export function isDmarcInvalid(status) {
  return invalidDmarcStates.includes(status);
}