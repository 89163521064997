export const EditDomainDropdownActionTypes = {
  SET_PUBLISHING_STATE: 'SET_PUBLISHING_STATE',
  REPLACE_REDIRECT_TO_DOMAIN: 'REPLACE_REDIRECT_TO_DOMAIN',
  ADD_CONTENT_TYPES: 'ADD_CONTENT_TYPES',
  REPLACE: 'REPLACE',
  REPLACE_BRAND: 'REPLACE_BRAND',
  MAKE_PRIMARY_ALSO_SECONDARY: 'MAKE_PRIMARY_ALSO_SECONDARY',
  MAKE_REDIRECT_SECONDARY: 'MAKE_REDIRECT_SECONDARY',
  EDIT_DOMAIN_SECURITY: 'EDIT_DOMAIN_SECURITY',
  MAKE_PRIMARY: 'MAKE_PRIMARY',
  ASSIGN_TEAMS: 'ASSIGN_TEAMS',
  REMOVE_CONTENT_TYPE: 'REMOVE_CONTENT_TYPE',
  REMOVE: 'REMOVE',
  SHOW_DNS_RECORDS: 'SHOW_DNS_RECORDS',
  VALIDATE_REVERSE_PROXY_DOMAIN: 'VALIDATE_REVERSE_PROXY_DOMAIN',
  VERIFY_SSL_CERTIFICATE: 'VERIFY_SSL_CERTIFICATE'
};