import http from 'hub-http/clients/apiClient';
import Immutable from 'immutable';
import getEarlyRequesterResultPromise from '../../earlyRequester/getEarlyRequesterResultPromise';
const API_PATH = 'business-units/v1/business-unit-displays/user/dropdown';
export function fetchBusinessUnits() {
  const makeRequest = () => http.get(`${API_PATH}`);
  const quickFetchPromise = getEarlyRequesterResultPromise('businessUnits');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}