'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import { convertDomainsListToMap, getTrackedDomainName } from '../../utils/domains/Domains';
import { removeDeletedDomainFromTrackedDomains } from '../../utils/domains/trackedDomains';
import DEFAULT_STATE from '../../data/domains/TrackedDomains';
function getDeletedDomainTrackedDomainsState(previousState, deletedDomainName) {
  const newTrackedDomainsMap = removeDeletedDomainFromTrackedDomains(previousState, deletedDomainName);
  return previousState.merge({
    domainIds: newTrackedDomainsMap.toList().map(getTrackedDomainName),
    domains: newTrackedDomainsMap
  });
}
export default function TrackedDomains(state = DEFAULT_STATE, action) {
  const {
    type,
    domains,
    deletedDomainName
  } = action;
  switch (type) {
    case ActionTypes.FETCH_TRACKED_DOMAINS_ATTEMPTED:
      return state.merge({
        trackedDomainsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_TRACKED_DOMAINS_FAILED:
      return state.merge({
        trackedDomainsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.FETCH_TRACKED_DOMAINS_SUCCEEDED:
      return state.merge({
        domainIds: domains.map(getTrackedDomainName),
        domains: convertDomainsListToMap(domains, getTrackedDomainName),
        trackedDomainsRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.REMOVE_DOMAIN_SUCCEEDED:
      return getDeletedDomainTrackedDomainsState(state, deletedDomainName);
    default:
      return state;
  }
}