'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { DomainConnectChangeTypes, DomainConnectStep, RequestStatus } from '../../Constants';
import DEFAULT_STATE from '../../data/domains/DomainConnect';
export default function DomainConnect(state = DEFAULT_STATE, action) {
  const {
    type,
    domainConnectChangeType
  } = action;
  switch (type) {
    case ActionTypes.APPLY_DOMAIN_TEMPLATE_ATTEMPTED:
      return state.merge({
        applyTemplateRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.APPLY_DOMAIN_TEMPLATE_SUCCEEDED:
      return state.merge({
        applyTemplateRequestStatus: RequestStatus.SUCCEEDED,
        currentStep: domainConnectChangeType && domainConnectChangeType === DomainConnectChangeTypes.PREPROVISIONING ? DomainConnectStep.connect : DomainConnectStep.authorized
      });
    case ActionTypes.APPLY_DOMAIN_TEMPLATE_FAILED:
      return state.merge({
        applyTemplateRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.RESET_DOMAIN_WIZARD:
      return DEFAULT_STATE;
    default:
      return state;
  }
}