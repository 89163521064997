
(function() {
  var result = { translations: { "hu": { "i18n": { "duration": { "seconds": "{{ seconds }} mp.", "minutes": "{{ minutes }} p.", "hours": "{{ hours }} \xF3.", "hoursAndMinutes": "{{ hours }} \xF3. {{ minutes }} p.", "days": "{{ days }} nap", "shortForm": { "seconds": "{{ seconds }} mp", "minutes": "{{ minutes }} p", "hours": "{{ hours }} \xF3", "days": "{{ days }} n", "hoursAndMinutes": "{{ hours }} \xF3 {{ minutes }} p" } }, "dateTime": { "quarterAndYear": "{{yearNumber}}. {{quarterNumber}}. n\xE9." }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{amountNumberValue}} {{currencyCode}}", "phoneNumberWithExtension": "{{phoneNumber}}, {{extension}} mell\xE9k" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "hu": "static-7.1148"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1148",
    "ar-eg": "static-7.1148",
    "bg": "static-7.1148",
    "bn": "static-7.1148",
    "ca-es": "static-7.1148",
    "cs": "static-7.1148",
    "da": "static-7.1148",
    "de": "static-7.1148",
    "el": "static-7.1148",
    "en-gb": "static-7.1148",
    "en": "static-7.1148",
    "es-mx": "static-7.1148",
    "es": "static-7.1148",
    "et": "static-7.1148",
    "fi": "static-7.1148",
    "fr-ca": "static-7.1148",
    "fr": "static-7.1148",
    "he-il": "static-7.1148",
    "hi": "static-7.1148",
    "hr": "static-7.1148",
    "hu": "static-7.1148",
    "id": "static-7.1148",
    "it": "static-7.1148",
    "ja": "static-7.1148",
    "ko": "static-7.1148",
    "lt": "static-7.1148",
    "ms": "static-7.1148",
    "nl": "static-7.1148",
    "no": "static-7.1148",
    "pl": "static-7.1148",
    "pt-br": "static-7.1148",
    "pt-pt": "static-7.1148",
    "ro": "static-7.1148",
    "ru": "static-7.1148",
    "sk": "static-7.1148",
    "sl": "static-7.1148",
    "sv": "static-7.1148",
    "th": "static-7.1148",
    "tl": "static-7.1148",
    "tr": "static-7.1148",
    "uk": "static-7.1148",
    "vi": "static-7.1148",
    "x-pseudo": "static-7.1148",
    "zh-cn": "static-7.1148",
    "zh-hk": "static-7.1148",
    "zh-tw": "static-7.1148"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:hu', {version: 'static-7.1148'}); }
})();


