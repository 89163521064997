'use es6';

import Immutable from 'immutable';
import { LegacyTlsOptions, LEGACY_TLS_OPTION, DEFAULT as DEFAULT_TLS_OPTION } from '../../enums/domains/TlsOptions';
import * as SecurityHeaders from '../../enums/domains/SecurityHeaders';
import * as SecuritySettingCategories from '../../enums/domains/SecuritySettingCategories';
import { ComplexSecuritySettings } from '../../Constants';
export const getContentSecurityHeaderFromSettings = securitySettings => {
  return securitySettings.get('domainSettings').filter(securitySetting => securitySetting.get('category') === SecuritySettingCategories.HTTP_HEADER && SecurityHeaders[securitySetting.get('subCategory')] !== undefined).reduce((collectedSecurityHeaders, securityHeader) => {
    const isComplexSecuritySetting = ComplexSecuritySettings.includes(securityHeader.get('subCategory'));
    let value = securityHeader.get('value');
    try {
      value = JSON.parse(value);
      if (!isComplexSecuritySetting) value = typeof value === 'object' ? value.value : securityHeader.get('value');
    } catch (e) {
      if (isComplexSecuritySetting) console.error(securityHeader.get('subCategory'), e);
    }
    return collectedSecurityHeaders.set(securityHeader.get('subCategory'), Immutable.fromJS({
      value
    }));
  }, Immutable.Map());
};
export const getFormattedTlsVersion = tlsVersion => {
  if (typeof tlsVersion === 'number') {
    tlsVersion = tlsVersion.toString();
  }
  if (typeof tlsVersion === 'string') {
    const formattedTlsVersion = tlsVersion !== LEGACY_TLS_OPTION ? tlsVersion.concat('+') : tlsVersion;
    if (LegacyTlsOptions.includes(formattedTlsVersion)) {
      return formattedTlsVersion;
    }
  }
  return DEFAULT_TLS_OPTION;
};
export default function getFormattedSecuritySettings(securitySettings) {
  const tlsConfiguration = securitySettings.get('minimumTlsVersion');
  const formattedSecuritySettings = getContentSecurityHeaderFromSettings(securitySettings).merge({
    [SecurityHeaders.HTTPS]: securitySettings.get('isSslOnly'),
    [SecurityHeaders.TLS]: Immutable.Map({
      value: getFormattedTlsVersion(tlsConfiguration)
    })
  });
  return formattedSecuritySettings;
}