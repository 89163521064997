'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../../Constants';
export default Immutable.Map({
  domainIds: Immutable.List(),
  domains: Immutable.Map(),
  emailRequestStatus: RequestStatus.UNINITIALIZED,
  spfRecords: Immutable.Set(),
  spfRecordsRequestStatus: RequestStatus.UNINITIALIZED,
  maxNumberOfEmailSendingDomainsForPortal: 2000
});