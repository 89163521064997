import { createSelector } from 'reselect';
import { gate } from 'hub-http/gates';
import { DomainManagerScopes, ScopesToCheck, DomainManagerGates, UrlRedirectsScopes, GatesToCheck, HubSpotSupportScopes } from '../Constants';
export const getUserInfo = state => state.auth.get('user');
export const getScopesArray = state => state.auth.get('scopes');
export const getGatesArray = state => state.auth.get('gates');
export const getPortalScopes = state => state.auth.get('portalScopes');
export const getFetchPortalScopesRequestStatus = state => state.auth.get('portalScopesRequestStatus');
export const getIsUserLanguageSetToEnglish = createSelector([getUserInfo], userInfo => userInfo.locale && userInfo.locale.includes('en'));
export const getScopesMap = createSelector([getScopesArray], scopes => {
  return ScopesToCheck.reduce((acc, scope) => Object.assign({}, acc, {
    [scope]: scopes.indexOf(scope) !== -1
  }), {});
});

/**
 * @deprecated - use getGatesArray instead with v3 gate()
 */
export const getGatesMap = createSelector([getGatesArray], gates => {
  return GatesToCheck.reduce((gatesMap, gateToCheck) => Object.assign({}, gatesMap, {
    [gateToCheck]: gates.indexOf(gateToCheck) !== -1
  }), {});
});
export const getPortalHasCustomDomainWriteScope = createSelector([getPortalScopes], portalScopes => portalScopes.includes(DomainManagerScopes.CUSTOM_DOMAIN_WRITE));
export const getPortalHasHsSitesWriteScope = createSelector([getPortalScopes], portalScopes => portalScopes.includes(DomainManagerScopes.HS_SITES_WRITE));
export const getIsUserReadOnly = createSelector([getPortalScopes, getScopesMap], (portalScopes, userScopes) => portalScopes.includes(DomainManagerScopes.DOMAINS_SETTINGS_WRITE) && !userScopes[DomainManagerScopes.DOMAINS_SETTINGS_WRITE]);
export const getIsUngatedForLegacyApexRedirectDomain = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.APEX_DOMAIN_REDIRECT_INFO];
});
const getPortalHasCustomApexDomainRedirectWriteScope = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.CUSTOM_APEX_DOMAIN_REDIRECT_WRITE);
});
export const getPortalHasReverseProxyDomainWriteScope = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.REVERSE_PROXY_DOMAIN_WRITE);
});
export const getIsAllowedToAddApexRedirectDomains = createSelector([getIsUngatedForLegacyApexRedirectDomain, getPortalHasCustomApexDomainRedirectWriteScope], (isUngatedForLegacyApexRedirectDomain, portalHasCustomApexDomainRedirectWriteScope) => isUngatedForLegacyApexRedirectDomain || portalHasCustomApexDomainRedirectWriteScope);

// to be addressed later when we're ready to separate read and write scopes in the UI
export const getUserHasSecurityHeaderReadOrWriteScope = createSelector([getScopesMap], scopes => !!scopes[DomainManagerScopes.SECURITY_HEADERS_READ] || !!scopes[DomainManagerScopes.SECURITY_HEADERS_WRITE]);
export const getPortalHasPartnerMatchingAppAccess = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.PARTNER_MATCHING_APP_ACCESS);
});
export const getPortalHasMultiDomainHostingScope = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.MULTI_DOMAIN_HOSTING);
});
export const getHasUrlRedirectsReadScope = createSelector([getScopesMap], scopes => {
  return !!scopes[UrlRedirectsScopes.READ_ACCESS];
});
export const getHasUrlRedirectsWriteScope = createSelector([getScopesMap], scopes => {
  return !!scopes[UrlRedirectsScopes.WRITE_ACCESS];
});
export const getHasTeamsContentPartioningScope = createSelector([getScopesMap], scopes => {
  return !!scopes[DomainManagerScopes.TEAMS_CONTENT_PARTIONING];
});
export const getHasTeamsAssignmentScope = createSelector([getScopesMap], scopes => {
  return !!scopes[DomainManagerScopes.TEAMS_ASSIGNMENT];
});
export const getHasDnsRecordsReadScope = createSelector([getScopesMap], scopes => !!scopes[DomainManagerScopes.DNS_RECORDS_READ]);
export const getIsUngatedForLegacyTlsVersion = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.LEGACY_TLS_VERSION];
});
export const getIsUngatedForSuspendAddDomain = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.SUSPEND_ADD_DOMAIN];
});
export const getUngatedForDisplayingTrackedDomains = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.TRACKED_DOMAINS];
});
export const getPortalHasEmailSendingDomainsAccessScope = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.EMAIL_DOMAINS);
});
export const getUserHasEmailSendingDomainsWriteScope = createSelector([getScopesMap], scopes => {
  return !!scopes[DomainManagerScopes.EMAIL_DOMAINS_WRITE];
});
const getIsUngatedForBusinessUnitsAccess = createSelector([getGatesMap], gates => !!gates[DomainManagerGates.ENFORCE_BUSINESS_UNITS_ACCESS_SCOPE]);
export const getIsPortalScopedForBusinessUnitsAccess = createSelector([getIsUngatedForBusinessUnitsAccess, getPortalScopes], (isUngatedForBusinessUnitsAccess, portalScopes) => isUngatedForBusinessUnitsAccess && portalScopes.includes(DomainManagerScopes.BUSINESS_UNITS_ACCESS));
export const getIsUserScopedToViewAllBusinessUnitsAssets = createSelector([getIsUngatedForBusinessUnitsAccess, getScopesMap], (isUngatedForBusinessUnitsAccess, scopes) => isUngatedForBusinessUnitsAccess && !!scopes[DomainManagerScopes.BUSINESS_UNITS_VIEW_ALL]);

// Note: Redundant - recommend negating getPortalHasMultiDomainHostingScope instead
export const getIsUsingScopeControlledAddPrimaryDomainWizard = createSelector([getPortalHasMultiDomainHostingScope], portalHasMultiDomainHostingScope => {
  return !portalHasMultiDomainHostingScope;
});
export const getPortalHasContentSettingsAccess = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.CONTENT_SETTINGS_ACCESS);
});
export const getPortalHasAdditionalBrandDomainsScope = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.ADDITIONAL_BRAND_DOMAINS);
});
export const getPortalHasSitemapsAccess = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.SITEMAPS_READ);
});
export const getIsUngatedForAdditionalBrandDomainsEnterpriseOnly = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.ADDITIONAL_BRAND_DOMAINS_ENTERPRISE_ONLY];
});
export const getPortalHasDedicatedIPCandidateScope = createSelector([getPortalScopes], portalScopes => portalScopes.includes(DomainManagerScopes.DEDICATED_IP_CANDIDATE));
export const getPortalHasDedicatedMarketingScope = createSelector([getPortalScopes], portalScopes => portalScopes.includes(DomainManagerScopes.DEDICATED_IP_MARKETING));
export const getPortalHasDedicatedTransactionalScope = createSelector([getPortalScopes], portalScopes => portalScopes.includes(DomainManagerScopes.DEDICATED_IP_TRANSACTIONAL));
export const getIsUngatedForCreateRedirectLimit = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.CREATE_REDIRECT_LIMIT];
});
export const getIsUngatedForReplacementRedirectHostingSetup = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.REPLACEMENT_REDIRECT_HOSTING_SETUP];
});

// Temporary gate, marks portals that BE is going to run a job on
export const getIsUngatedForUpdateMatchQueryStringJob = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.UPDATE_MATCH_QUERY_STRING_JOB];
});

// Temporary gate, marks portals fixed by BE job
export const getIsUngatedForMatchEmptyQueryString = createSelector([getGatesMap], gates => {
  return !!gates[DomainManagerGates.MATCH_EMPTY_QUERY_STRING];
});

/**
 * Returns true if gates indicate that portal should NOT display the "match query string" checkbox
 *
 * Note: URL redirect components should also check these conditions before hiding:
 * 1. That at least one edited URL redirect is "flexible"
 * 2. URL redirect's "original URL" field does not include any query params
 */
export const getIsUngatedForHideMatchQueryStrings = createSelector([getIsUngatedForUpdateMatchQueryStringJob, getIsUngatedForMatchEmptyQueryString], (isUngatedForUpdateMatchQueryStringJob, isUngatedForMatchEmptyQueryString) => {
  return isUngatedForUpdateMatchQueryStringJob && !isUngatedForMatchEmptyQueryString;
});
export const selectIsUngatedForReverseProxySetup = createSelector([getGatesMap], gates => !!gates[DomainManagerGates.REVERSE_PROXY_SETUP]);
export const getIsUngatedForAutomaticRedirect = createSelector([getGatesMap], gates => !!gates[DomainManagerGates.AUTOMATIC_REDIRECT]);
export const getIsUngatedForSslProvisioningTool = createSelector([getGatesMap], gates => !!gates[DomainManagerGates.SSL_PROVISIONING_TOOL]);
export const selectIsUngatedForSpfAndDmarc = createSelector([getGatesMap], gates => !!gates[DomainManagerGates.SPF_AND_DMARC]);
export const getPortalHasHubSpotSupportAccessScope = createSelector([getPortalScopes], portalScopes => Object.values(HubSpotSupportScopes).some(scope => portalScopes.includes(scope)));
export const getPortalHasSmsProductAccess = createSelector([getPortalScopes], portalScopes => {
  return portalScopes.includes(DomainManagerScopes.SMS_PRODUCT_ACCESS);
});
export const getPortalSupportsSmsBrandedLinks = createSelector([getPortalHasSmsProductAccess], hasPortalScope => hasPortalScope);
export const getPortalUngatedForDedicatedIPBusinessUnits = createSelector([getGatesArray], gates => gates.includes(gate('DedIP:ShowBusinessUnits')));
export const getPortalHasDedicatedIPBusinessUnitsAccess = createSelector([getPortalUngatedForDedicatedIPBusinessUnits, getIsPortalScopedForBusinessUnitsAccess], (isUngated, hasBusinessUnitsAccess) => isUngated && hasBusinessUnitsAccess);
export const getPortalIsUngatedForEntri = createSelector([getGatesArray], gates => gates.includes(gate('Domains:EnableEntri')));