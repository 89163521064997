'use es6';

import * as client from '../api/PortalSettings';
import * as ActionTypes from './ActionTypes';
export function fetchPortalScopes() {
  return dispatch => {
    dispatch({
      type: ActionTypes.FETCH_PORTAL_SCOPE_ATTEMPTED
    });
    return client.fetchPortalScopes().then(response => dispatch({
      type: ActionTypes.FETCH_PORTAL_SCOPE_SUCCEEDED,
      scopes: response.get('scopes')
    }), () => dispatch({
      type: ActionTypes.FETCH_PORTAL_SCOPE_FAILED
    }));
  };
}