'use es6';

import { generatePath } from 'react-router-dom';
import { history } from '../createHistory';
import { AddDomainWizardSteps, DomainType, UrlPathParams, DomainWizardQueryParams, VIEW_ALL_BUSINESS_UNITS_VALUE } from '../../Constants';
import { getEmailSendingDomainPunycodeName } from '../../selectors/domains/EmailSendingDomains';
export function navigateToDomainListing({
  params: {
    businessUnitId
  }
}) {
  let path;
  if (businessUnitId) {
    path = generatePath('/business-unit/:businessUnitId', {
      businessUnitId
    });
  } else {
    path = '/';
  }
  history.push(path);
}
export function handleOpenSecuritySettingsPanel({
  params: {
    businessUnitId
  }
}, domainId) {
  let path = `/${UrlPathParams.SECURITY_SETTINGS}/:domainId`;
  if (businessUnitId) {
    path = `/business-unit/:businessUnitId${path}`;
  }
  history.push(generatePath(path, {
    businessUnitId,
    domainId
  }));
}
export const handleOpenSslVerificationWizard = ({
  params: {
    businessUnitId
  }
}, domainId) => {
  let path = `/${UrlPathParams.SSL_VERIFICATION}/:domainId`;
  if (businessUnitId) {
    path = `/business-unit/:businessUnitId${path}`;
  }
  history.push(generatePath(path, {
    businessUnitId,
    domainId
  }));
};
export function isOnVerifyEmailDomainPage(params) {
  return params.step === AddDomainWizardSteps.VERIFY_EMAIL_DOMAIN;
}
export function isOnVerifyUrlsPage(params) {
  return params.step === AddDomainWizardSteps.VERIFY_URLS;
}
export function isOnVerifyRedirectPage(params) {
  return params.step === AddDomainWizardSteps.VERIFY_REDIRECT;
}
export function isOnReviewUrlsPage(params) {
  return params.step === AddDomainWizardSteps.REVIEW_URLS || params.step === AddDomainWizardSteps.REVIEW_REPLACEMENTS;
}
export function isOnPageBeforeHostingSetup(params) {
  if (params.domainType === DomainType.REDIRECT) {
    return isOnVerifyRedirectPage(params);
  }
  if (params.domainType === DomainType.EMAIL) {
    return params.step === AddDomainWizardSteps.VERIFY_EMAIL_DOMAIN;
  }
  if (params.domainType === DomainType.REPLACE) {
    return params.step === AddDomainWizardSteps.REVIEW_REPLACEMENTS;
  }
  return isOnReviewUrlsPage(params);
}
export function getStepNameFromUrlPathParam(params) {
  return params.step;
}
export function getUrlPathToAddWizardStep({
  params: {
    businessUnitId
  }
}, step, domainType) {
  let path = `/${UrlPathParams.ADD_DOMAIN}/:step`;
  if (businessUnitId) {
    path = `/business-unit/:businessUnitId${path}`;
  }
  if (domainType) {
    path = `${path}/${domainType}`;
  }
  return generatePath(path, {
    businessUnitId,
    step
  });
}
export function getContinueDomainSetupUrlPath(match, domain, domainType) {
  const baseUrl = `${getUrlPathToAddWizardStep(match, AddDomainWizardSteps.HOSTING_SETUP)}/${domainType}`;
  return domainType === DomainType.EMAIL ? `${baseUrl}/${getEmailSendingDomainPunycodeName(domain)}` : `${baseUrl}/${domain.get('setupTaskId')}/${domain.get('id')}`;
}
export function getPathParamToFirstWizardStep(domainType, isCalledFromAddSpecificDomainTypeButton = false) {
  if (domainType === DomainType.REDIRECT || domainType === DomainType.EMAIL || domainType === DomainType.DEDICATED_IP && isCalledFromAddSpecificDomainTypeButton || domainType === DomainType.PRIMARY || domainType === DomainType.SECONDARY) {
    return UrlPathParams.SELECT_DOMAIN;
  }
  if (domainType === DomainType.REPLACE) {
    return UrlPathParams.SELECT_BRAND;
  }
  return UrlPathParams.CONTENT_TYPE;
}
export function didAccessFirstPageOfFlow(params, isUsingScopeControlledAddPrimaryDomainWizard = false) {
  return params.step === AddDomainWizardSteps.SELECT_DOMAIN && params.domainType === DomainType.PRIMARY && isUsingScopeControlledAddPrimaryDomainWizard || params.step === AddDomainWizardSteps.CONTENT_TYPE && (!isUsingScopeControlledAddPrimaryDomainWizard && (params.domainType === DomainType.PRIMARY || params.domainType === DomainType.SECONDARY) || params.domainType === DomainType.DEDICATED_IP) || params.step === AddDomainWizardSteps.SELECT_DOMAIN && (params.domainType === DomainType.REDIRECT || params.domainType === DomainType.EMAIL || params.domainType === DomainType.DEDICATED_IP) || params.step === AddDomainWizardSteps.SELECT_BRAND && params.domainType === DomainType.REPLACE;
}
export function getRedirectUrlFromQueryParams(locationObj) {
  if (!locationObj || !locationObj.query) {
    return undefined;
  }
  return locationObj.query[DomainWizardQueryParams.REDIRECT_URL] || undefined;
}
export function getBusinessUnitIdFromQueryParams(params) {
  return params.businessUnitId !== VIEW_ALL_BUSINESS_UNITS_VALUE ? parseInt(params.businessUnitId, 10) : undefined;
}
export function getBrandToReplaceFromQueryParams(params) {
  return params.brandToReplace;
}