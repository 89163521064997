'use es6';

import * as ActionTypes from '../../actions/ActionTypes';
import { RequestStatus } from '../../Constants';
import DEFAULT_STATE from '../../data/domains/DomainTeams';
export default function DomainTeams(state = DEFAULT_STATE, action) {
  const {
    type,
    teamIds
  } = action;
  switch (type) {
    case ActionTypes.FETCH_DOMAIN_TEAMS_ATTEMPTED:
      return state.merge({
        domainTeamsRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.FETCH_DOMAIN_TEAMS_SUCCEEDED:
      return state.merge({
        domainTeamsRequestStatus: RequestStatus.SUCCEEDED,
        teamIds
      });
    case ActionTypes.FETCH_DOMAIN_TEAMS_FAILED:
      return state.merge({
        domainTeamsRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.DESELECT_DOMAIN:
      return DEFAULT_STATE;
    default:
      return state;
  }
}