import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import DedicatedIPAPIv2 from '../api/DedicatedIPv2';
import RequestStatusTypes from 'settings-ui-lib/constants/RequestStatusTypes';
import { DedicatedIPModalActions } from '../constants/DedicatedIP';
import { getDedicatedIPLinkedBusinessUnits, getIsDefaultForUnassignedBusinessUnits } from '../utils/domains/dedicatedIP';
export const fetchDedicatedIPNeedsAccountSetup = createAsyncThunk('dedicatedIPv2/fetchDedicatedIPNeedsAccountSetup', DedicatedIPAPIv2.fetchDedicatedIPNeedsAccountSetup);
export const fetchAllDedicatedIPStatuses = createAsyncThunk('dedicatedIPv2/fetchAllDedicatedIPStatuses', DedicatedIPAPIv2.fetchAllDedicatedIPStatuses);

// TODO: use this and delete from v1 actions / API
export const addDedicatedIP = createAsyncThunk('dedicatedIPv2/addDedicatedIP', DedicatedIPAPIv2.addDedicatedIP);
export const disconnectDedicatedIP = createAsyncThunk('dedicatedIPv2/disconnectDedicatedIP', DedicatedIPAPIv2.disconnectDedicatedIP);
export const fetchDedicatedIPDNSRecords = createAsyncThunk('dedicatedIPv2/fetchDedicatedIPDNSRecords', DedicatedIPAPIv2.fetchDedicatedIPDNSRecords);
export const verifyDedicatedIPDNSRecords = createAsyncThunk('dedicatedIPv2/verifyDedicatedIPDNSRecords', DedicatedIPAPIv2.verifyDedicatedIPDNSRecords);
export const validateDedicatedIPDNSConfiguration = createAsyncThunk('dedicatedIPv2/validateDedicatedIPDNSConfiguration', DedicatedIPAPIv2.validateDedicatedIPDNSConfiguration);
export const updateDedicatedIPBusinessUnits = createAsyncThunk('dedicatedIPv2/updateDedicatedIPBusinessUnits', DedicatedIPAPIv2.updateDedicatedIPBusinessUnits);
export const makeDedicatedIPDefault = createAsyncThunk('dedicatedIPv2/makeDedicatedIPDefault', DedicatedIPAPIv2.makeDedicatedIPDefault);
export const initialState = {
  DEDICATED_MARKETING: {
    requestStatus: RequestStatusTypes.UNINITIALIZED,
    error: {},
    statusMap: {},
    statusList: [],
    businessUnitToSmtpDomainMap: {},
    smtpDomainToBusinessUnitsMap: {},
    defaultSmtpDomain: ''
  },
  DEDICATED_TRANSACTIONAL: {
    requestStatus: RequestStatusTypes.UNINITIALIZED,
    error: {},
    statusMap: {},
    statusList: [],
    // Currently unused; only marketing supports BUs
    businessUnitToSmtpDomainMap: {},
    smtpDomainToBusinessUnitsMap: {},
    defaultSmtpDomain: ''
  },
  needsAccountSetup: {
    requestStatus: RequestStatusTypes.UNINITIALIZED,
    isSandbox: false,
    canSetupMoreMarketingIPs: false,
    canSetupMoreTransactionalIPs: false,
    error: {}
  },
  modal: {
    requestStatusMap: {},
    error: {}
  }
};
export const dedicatedIPv2Slice = createSlice({
  name: 'dedicatedIPv2',
  initialState,
  reducers: {
    resetDedicatedIPModal: state => produce(state, draft => {
      const smtpDomain = draft.modal.updatedSmtpDomain;
      if (smtpDomain) {
        // Reset state related to this smtpDomain
        // Other requests may still be pending (leave rest of map alone)
        delete draft.modal.requestStatusMap[smtpDomain];
        draft.modal.updatedSmtpDomain = undefined;
        draft.modal.error = {};
        draft.modal.accountType = undefined;
        draft.modal.modalAction = undefined;
      }
    })
  },
  extraReducers: builder => {
    builder
    // Fetch IP limits, portal sandbox
    .addCase(fetchDedicatedIPNeedsAccountSetup.pending, state => produce(state, draft => {
      draft.needsAccountSetup.requestStatus = RequestStatusTypes.PENDING;
    })).addCase(fetchDedicatedIPNeedsAccountSetup.fulfilled, (state, action) => produce(state, draft => {
      const {
        isSandbox,
        canSetupMoreMarketingIps,
        canSetupMoreTransactionalIps
      } = action.payload;
      draft.needsAccountSetup.requestStatus = RequestStatusTypes.SUCCEEDED;
      draft.needsAccountSetup.isSandbox = isSandbox;
      draft.needsAccountSetup.canSetupMoreMarketingIPs = canSetupMoreMarketingIps;
      draft.needsAccountSetup.canSetupMoreTransactionalIPs = canSetupMoreTransactionalIps;
    })).addCase(fetchDedicatedIPNeedsAccountSetup.rejected, (state, action) => produce(state, draft => {
      draft.needsAccountSetup.requestStatus = RequestStatusTypes.FAILED;
      draft.needsAccountSetup.error = action.error;
    }))
    // Fetch dedicated marketing or transactional account setup statuses
    .addCase(fetchAllDedicatedIPStatuses.pending, (state, action) => produce(state, draft => {
      const {
        accountType
      } = action.meta.arg;
      draft[accountType].requestStatus = RequestStatusTypes.PENDING;
    })).addCase(fetchAllDedicatedIPStatuses.fulfilled, (state, action) => produce(state, draft => {
      const {
        accountType
      } = action.meta.arg;
      const statuses = action.payload;
      draft[accountType].requestStatus = RequestStatusTypes.SUCCEEDED;
      // Sort statuses by smtpDomain since BE return order changes on update
      draft[accountType].statusList = statuses.sort((a, b) => a.smtpDomain.localeCompare(b.smtpDomain));
      statuses.forEach(status => {
        // Store statuses by smtpDomain for performant lookup
        draft[accountType].statusMap[status.smtpDomain] = status;

        // Business unit assignment state
        if (getIsDefaultForUnassignedBusinessUnits(status)) {
          draft[accountType].defaultSmtpDomain = status.smtpDomain;
        }
        const businessUnits = getDedicatedIPLinkedBusinessUnits(status);
        // smtpDomains without business units will get an empty array []
        draft[accountType].smtpDomainToBusinessUnitsMap[status.smtpDomain] = businessUnits;
        businessUnits.forEach(businessUnit => {
          draft[accountType].businessUnitToSmtpDomainMap[businessUnit] = status.smtpDomain;
        });
      });
    })).addCase(fetchAllDedicatedIPStatuses.rejected, (state, action) => produce(state, draft => {
      const {
        accountType
      } = action.meta.arg;
      draft[accountType].requestStatus = RequestStatusTypes.FAILED;
      draft[accountType].error = action.error;
    }))
    // Disconnect dedicated IP status by smtpDomain
    .addCase(disconnectDedicatedIP.pending, (state, action) => produce(state, draft => {
      const {
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.PENDING;
    })).addCase(disconnectDedicatedIP.fulfilled, (state, action) => produce(state, draft => {
      const {
        accountType,
        smtpDomain
      } = action.meta.arg;

      // Request status state for success/failure handling
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.accountType = accountType;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.SUCCEEDED;
      draft.modal.modalAction = DedicatedIPModalActions.disconnect;

      // Update IP statuses
      delete draft[accountType].statusMap[smtpDomain];
      const statusListIndexToRemove = draft[accountType].statusList.findIndex(status => status.smtpDomain === smtpDomain);
      draft[accountType].statusList.splice(statusListIndexToRemove, 1);
    })).addCase(disconnectDedicatedIP.rejected, (state, action) => produce(state, draft => {
      const {
        accountType,
        smtpDomain
      } = action.meta.arg;
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.accountType = accountType;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.FAILED;
      draft.modal.error = action.error;
      draft.modal.modalAction = DedicatedIPModalActions.disconnect;
    }))
    // Assign Business Units to Dedicated IPs
    .addCase(updateDedicatedIPBusinessUnits.pending, (state, action) => produce(state, draft => {
      const {
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.PENDING;
    })).addCase(updateDedicatedIPBusinessUnits.fulfilled, (state, action) => produce(state, draft => {
      const {
        accountType,
        smtpDomain,
        businessUnits
      } = action.meta.arg;
      draft[accountType].smtpDomainToBusinessUnitsMap[smtpDomain] = businessUnits;
      businessUnits.forEach(businessUnit => {
        const oldSmtpDomain = draft[accountType].businessUnitToSmtpDomainMap[businessUnit];
        if (oldSmtpDomain) {
          draft[accountType].smtpDomainToBusinessUnitsMap[oldSmtpDomain] = draft[accountType].smtpDomainToBusinessUnitsMap[oldSmtpDomain].filter(bu => bu !== businessUnit);
        }
        draft[accountType].businessUnitToSmtpDomainMap[businessUnit] = smtpDomain;
      });
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.SUCCEEDED;
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.modalAction = DedicatedIPModalActions.updateBusinessUnits;
    })).addCase(updateDedicatedIPBusinessUnits.rejected, (state, action) => produce(state, draft => {
      const {
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.FAILED;
      draft.modal.error = action.error;
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.modalAction = DedicatedIPModalActions.updateBusinessUnits;
    }))
    // Make default for unassigned business units
    .addCase(makeDedicatedIPDefault.pending, (state, action) => produce(state, draft => {
      const {
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.PENDING;
    })).addCase(makeDedicatedIPDefault.fulfilled, (state, action) => produce(state, draft => {
      const {
        accountType,
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.SUCCEEDED;
      draft[accountType].defaultSmtpDomain = smtpDomain;
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.modalAction = DedicatedIPModalActions.makeDefault;
    })).addCase(makeDedicatedIPDefault.rejected, (state, action) => produce(state, draft => {
      const {
        smtpDomain
      } = action.meta.arg;
      draft.modal.requestStatusMap[smtpDomain] = RequestStatusTypes.FAILED;
      draft.modal.error = action.error;
      draft.modal.updatedSmtpDomain = smtpDomain;
      draft.modal.modalAction = DedicatedIPModalActions.makeDefault;
    }));
  }
});
export const {
  resetDedicatedIPModal
} = dedicatedIPv2Slice.actions;