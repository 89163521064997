const mapping = {
  EMAIL: {
    langKey: 'settings-ui-nav.backToProductName.email',
    path: '/email'
  },
  SOCIAL: {
    langKey: 'settings-ui-nav.backToProductName.social',
    path: '/social'
  },
  ADS: {
    langKey: 'settings-ui-nav.backToProductName.ads',
    path: '/ads'
  },
  FORMS: {
    langKey: 'settings-ui-nav.backToProductName.forms',
    path: '/forms'
  },
  DEALS_RESTORE: {
    langKey: 'settings-ui-nav.backToProductName.deals',
    path: /(sales|contacts)\/\d+\/objects\/0-3\/restore/i
  },
  DEALS: {
    langKey: 'settings-ui-nav.backToProductName.deals',
    path: /(sales|contacts)\/\d+\/deals/i
  },
  TASKS: {
    langKey: 'settings-ui-nav.backToProductName.tasks',
    path: /(sales|contacts)\/\d+\/tasks/i
  },
  MEETINGS: {
    langKey: 'settings-ui-nav.backToProductName.meetings',
    path: '/meetings'
  },
  TICKETS_RESTORE: {
    langKey: 'settings-ui-nav.backToProductName.tickets',
    path: /(sales|contacts)\/\d+\/objects\/0-5\/restore/i
  },
  TICKETS: {
    langKey: 'settings-ui-nav.backToProductName.tickets',
    path: /(sales|contacts)\/\d+\/tickets/i
  },
  CONTACTS_RESTORE: {
    langKey: 'settings-ui-nav.backToProductName.contacts',
    path: /(sales|contacts)\/\d+\/objects\/0-1\/restore/i
  },
  CONTACTS: {
    langKey: 'settings-ui-nav.backToProductName.contacts',
    path: /(sales|contacts)\/\d+\/contacts/i
  },
  COMPANIES_RESTORE: {
    langKey: 'settings-ui-nav.backToProductName.companies',
    path: /(sales|contacts)\/\d+\/objects\/0-2\/restore/i
  },
  COMPANIES: {
    langKey: 'settings-ui-nav.backToProductName.companies',
    path: /(sales|contacts)\/\d+\/companies/i
  },
  ANALYTICS_TOOLS: {
    langKey: 'settings-ui-nav.backToProductName.analyticsTools',
    path: '/analytics'
  },
  CUSTOM_REPORTS: {
    langKey: 'settings-ui-nav.backToProductName.reports',
    path: '/reports'
  },
  CONTACT: {
    langKey: 'settings-ui-nav.backToProductName.contact',
    path: /(sales|contacts)\/\d+\/contact/i
  },
  COMPANY: {
    langKey: 'settings-ui-nav.backToProductName.company',
    path: /(sales|contacts)\/\d+\/company/i
  },
  DEAL: {
    langKey: 'settings-ui-nav.backToProductName.deal',
    path: /(sales|contacts)\/\d+\/deal/i
  },
  CUSTOMER_PORTAL: {
    langKey: 'settings-ui-nav.backToProductName.customerPortal',
    path: /settings\/\d+\/customer-portal/i
  },
  TICKET: {
    langKey: 'settings-ui-nav.backToProductName.ticket',
    path: /(sales|contacts)\/\d+\/ticket/i
  },
  INBOX: {
    langKey: 'settings-ui-nav.backToProductName.inbox',
    path: '/live-messages'
  },
  BLOGS: {
    langKey: 'settings-ui-nav.backToProductName.blog',
    path: /(website|blog)\/\d+\/(blog)/i
  },
  SITE_PAGES: {
    langKey: 'settings-ui-nav.backToProductName.websitePages',
    path: /(website|pages)\/\d+\/(pages\/site|manage\/site)/i
  },
  LANDING_PAGES: {
    langKey: 'settings-ui-nav.backToProductName.landingPages',
    path: /(website|pages)\/\d+\/(pages\/landing|manage\/landing)/i
  },
  PAGE_EDITOR: {
    langKey: 'settings-ui-nav.backToProductName.websitePages',
    path: /content\/\d+\/edit/i
  },
  BLOG_EDITOR: {
    langKey: 'settings-ui-nav.backToProductName.blog',
    path: /blog\/\d+\/edit/i
  },
  BLOG_DETAIL_PAGE: {
    langKey: 'settings-ui-nav.backToProductName.blog',
    path: /content-detail\/\d+\/blog-post/i
  },
  SITE_DETAIL_PAGE: {
    langKey: 'settings-ui-nav.backToProductName.websitePages',
    path: /content-detail\/\d+\/(site-page|landing-page)/i
  },
  FORECASTING: {
    langKey: 'settings-ui-nav.backToProductName.forecasting',
    path: /forecasting\/\d+\//i
  },
  CALL: {
    langKey: 'settings-ui-nav.backToProductName.call',
    path: /calls\/\d+\/review/i
  },
  CALLS: {
    langKey: 'settings-ui-nav.backToProductName.calls',
    path: /contacts\/\d+\/objects\/0-48/i
  },
  MEMBERSHIPS: {
    langKey: 'settings-ui-nav.backToProductName.memberships',
    path: /memberships\/\d+\/(access-groups|members)/i
  },
  CASE_STUDIES: {
    langKey: 'settings-ui-nav.backToProductName.caseStudies',
    path: '/case-study'
  },
  LEADS: {
    langKey: 'settings-ui-nav.backToProductName.leads',
    path: '/lead-overview'
  },
  COURSES: {
    langKey: 'settings-ui-nav.backToProductName.courses',
    path: /contacts\/\d+\/objects\/0-410/i
  },
  COURSE: {
    langKey: 'settings-ui-nav.backToProductName.course',
    path: /contacts\/\d+\/record\/0-410/i
  },
  LISTINGS: {
    langKey: 'settings-ui-nav.backToProductName.listings',
    path: /contacts\/\d+\/objects\/0-420/i
  },
  LISTING: {
    langKey: 'settings-ui-nav.backToProductName.listing',
    path: /contacts\/\d+\/record\/0-420/i
  },
  APPOINTMENTS: {
    langKey: 'settings-ui-nav.backToProductName.appointments',
    path: /contacts\/\d+\/objects\/0-421/i
  },
  APPOINTMENT: {
    langKey: 'settings-ui-nav.backToProductName.appointment',
    path: /contacts\/\d+\/record\/0-421/i
  },
  SERVICES: {
    langKey: 'settings-ui-nav.backToProductName.services',
    path: /contacts\/\d+\/objects\/0-162/i
  },
  SERVICE: {
    langKey: 'settings-ui-nav.backToProductName.service',
    path: /contacts\/\d+\/record\/0-162/i
  }
};
export default mapping;