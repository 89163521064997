'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../../Constants';
import * as ActionTypes from '../../actions/ActionTypes';
import { initializeMappedColumns, clearMappedField, clearPreprocessedDataField } from '../../utils/redirects/imports';
export const defaultImportState = Immutable.fromJS({
  importedFile: {},
  redirectsToImportCount: 0,
  preprocessedData: Immutable.List(),
  preprocessRequestStatus: RequestStatus.UNINITIALIZED,
  overwriteExistingRedirects: false,
  overwriteExistingNote: false,
  mappedColumnNames: Immutable.Map({}),
  note: ''
});
export default function Import(state = defaultImportState, action) {
  const {
    type,
    importedFile,
    redirectsToImportCount,
    preprocessedData,
    overwriteExistingRedirects,
    overwriteExistingNote,
    fileHeaderColumnName,
    systemColumnName,
    note
  } = action;
  const currentMappedColumns = state.get('mappedColumnNames');
  const currentPreprocessedData = state.get('preprocessedData');
  switch (type) {
    case ActionTypes.SET_REDIRECTS_IMPORTED_FILE_ID:
      return state.merge({
        importedFile
      });
    case ActionTypes.PREPROCESS_REDIRECTS_SUCCEEDED:
      return state.merge({
        redirectsToImportCount,
        preprocessedData,
        mappedColumnNames: initializeMappedColumns(preprocessedData),
        preprocessRequestStatus: RequestStatus.SUCCEEDED
      });
    case ActionTypes.PREPROCESS_REDIRECTS_FAILED:
      return state.merge({
        preprocessRequestStatus: RequestStatus.FAILED
      });
    case ActionTypes.PREPROCESS_REDIRECTS_ATTEMPTED:
      return state.merge({
        preprocessRequestStatus: RequestStatus.PENDING
      });
    case ActionTypes.TOGGLE_OVERWRITE_EXISTING_REDIRECTS:
      return state.merge({
        overwriteExistingRedirects
      });
    case ActionTypes.TOGGLE_OVERWRITE_EXISTING_NOTE:
      return state.merge({
        overwriteExistingNote
      });
    case ActionTypes.MAP_REDIRECT_FIELD:
      return state.setIn(['mappedColumnNames', systemColumnName], fileHeaderColumnName);
    case ActionTypes.CLEAR_MAPPED_REDIRECT_FIELD:
      return state.merge({
        mappedColumnNames: clearMappedField(fileHeaderColumnName, currentMappedColumns),
        preprocessedData: clearPreprocessedDataField(fileHeaderColumnName, currentPreprocessedData)
      });
    case ActionTypes.UPDATE_IMPORT_REDIRECTS_NOTE:
      return state.merge({
        note
      });
    case ActionTypes.IMPORT_REDIRECTS_ATTEMPTED:
    case ActionTypes.INITIALIZE_IMPORTS_STATE:
      return defaultImportState;
    default:
      return state;
  }
}