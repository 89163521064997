'use es6';

import Immutable from 'immutable';
import { RequestStatus } from '../../Constants';
export const reverseProxyChecksForDomainDefaultState = {
  fetchStatus: RequestStatus.UNINITIALIZED,
  checks: new Map(),
  failedChecks: []
};

/**
 * stagedDomainUsageTypes = superset of:
 * 1. Existing conflicting domain usage types (based on add secondary vs. primary)
 * 2. User's ContentTypePicker selections
 *
 * getUpdateContentTypesRequestPayload will add stagedDomainUsageTypes
 * to existing ones before making updateDomain API call
 */
export default Immutable.Map({
  domainId: -1,
  editDropdownDomainUsageType: null,
  requireHttps: false,
  manuallyMarkedAsResolving: false,
  updateRequestStatus: RequestStatus.UNINITIALIZED,
  stagedDomainUsageTypes: Immutable.List(),
  dsnRecordsForDomain: Immutable.Map({
    fetchStatus: RequestStatus.UNINITIALIZED
  }),
  reverseProxyCheckForDomain: reverseProxyChecksForDomainDefaultState
});